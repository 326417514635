import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import htmlContentService from '../../services/htmlContent.service';




const SecondaryContentAddComponent = (props) => {
    const closeRef = useRef();
    const [lessonId, setLessonId] = useState(-1);
    const [htmlContentId, setHtmlContentId] = useState(-1);
    const [title, setTitle] = useState("");

    useEffect(() => {
        setLessonId(props.lessonId);
        setHtmlContentId(props.htmlContentId);
    }, []);

    function addSecondaryContent() {
        if(title == ""){
            toast.info("عنوان را وارد کنید");
            return
        }
        htmlContentService.insert(lessonId, "", "", title).then(
            (response) => {
                closeRef.current.click();
                props.getSecondaries();
            },
            (error) => {

            }
        );
    }

    function getSecondaryContent(htmlContentId) {
        htmlContentService.getHtmlContentById(htmlContentId).then(
            (response) => {

            },
            (error) => {

            }
        );
    }

    function titleInputHandler(e) {
        setTitle(e.target.value);
    }

    return (
        <div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">افزودن</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="input-group">
                                <span class="input-group-text">عنوان</span>
                                <input type="text" aria-label="First name" class="form-control" onChange={(e) => titleInputHandler(e)} />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>بستن</button>
                            <button type="button" class="btn btn-primary" onClick={() => addSecondaryContent()}>ذخیره</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondaryContentAddComponent;



