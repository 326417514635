import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import courseStudentsService from "../../services/courseStudents.service";
import htmlContentService from "../../services/htmlContent.service";


const AzmoonComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return (

        <div>
            azmoon page
        </div>

    );
};

export default AzmoonComponent;



