import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useState } from "react";
import courseService from "../../services/course.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LessonsListEnableComponent from "./CourseLessonsComponent";
import CourseSettingComponent from "./CourseSetting.component";
import { useLocation } from "react-router-dom";
import CourseStudentsComponent from "./CourseStudentsComponent";
import AllDontCheckAnswersOfCourseComponent from "./AllDontCheckAnswersOfCourseComponent";
import CanvasJSReact from '@canvasjs/react-charts';
import lessonService from "../../services/lesson.service";
import { toast } from "react-toastify";
import chartsService from "../../services/charts.service";

const LessonChartComponent = (props) => {
    const { state } = useLocation();
    const [options, setOptions] = useState([]);
    const [widthChangeCount, setWidthChangeCount] = useState(0);
    const [lessons, setLessons] = useState([]);
    const [selectedLessonIndex, setSelectedLessonIndex] = useState(-1);
    const [selectedAzmoonIndex, setSelectedAzmoonIndex] = useState(-1);
    const [courseId, setCourseId] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setCourseId(state.courseId);
        getChartOfCourse(state.courseId);
        getAllLessonsOfCourseWithAzmoons(state.courseId);

        window.addEventListener("resize", handleResize, false);
    }, []);

    const handleResize = () => {
        setWidthChangeCount(widthChangeCount + 1);
    }

    function getAllLessonsOfCourseWithAzmoons(courseId) {
        setIsLoading(true);
        lessonService.getAllLessonsOfCourseWithAzmoons(courseId).then(
            (response) => {
                setLessons(response.data);
                // setIsLoading(false);
            },
            (error) => {
                // setIsLoading(false);
            }
        );
    }

    function createOptions(data) {
        let localoptions = [];
        for (let i = 0; i < data.length; i++) {
            localoptions.push({ label: data[i]["name"] + ' ' + data[i]["family"], y: data[i]["score"] },);
        }

        const options1 = {
            title: {
                text: ""
            },
            axisY: {
                maximum: data.length > 0 && data[0]["maxScore"],
            },
            data: [{
                type: "column",
                dataPoints: localoptions
            }]
        }
        setOptions(options1);
        console.log(options1);
    }

    function getAzmoonChartOfLesson(courseId, azmoonId) {
        setIsLoading(true);
        chartsService.getAzmoonChartOfLesson(courseId, azmoonId).then(
            (response) => {
                createOptions(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);

            }
        );
    }

    function getChartOfCourse(courseId) {
        setIsLoading(true);
        chartsService.getChartOfCourse(courseId).then(
            (response) => {
                createOptions(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);

            }
        );
    }

    function getLessonChartOfLesson(courseId, lessonId) {
        setIsLoading(true);
        chartsService.getLessonChartOfLesson(courseId, lessonId).then(
            (response) => {
                createOptions(response.data);
                // console.log(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);

            }
        );
    }

    function btnShowClick() {
        if (selectedLessonIndex == -1 && selectedAzmoonIndex == -1) {
            getChartOfCourse(courseId);
        }
        else if (selectedLessonIndex != -1 && selectedAzmoonIndex == -1) {
            getLessonChartOfLesson(courseId, lessons[selectedLessonIndex]["id"]);
        }
        else if (selectedLessonIndex != -1 && selectedAzmoonIndex != -1) {
            getAzmoonChartOfLesson(courseId, lessons[selectedLessonIndex]["azmoons"][selectedAzmoonIndex]["id"]);
            // console.log(lessons[selectedLessonIndex]["azmoons"][selectedAzmoonIndex]["id"]);
        }
    }

    function lessonChangeHandler(value) {
        setSelectedAzmoonIndex(-1);
        setSelectedLessonIndex(value);
    }

    return (
        <div className="w-100">
            <div className="d-flex my-4">
                <select class="form-select col mx-1" onChange={(e) => lessonChangeHandler(e.target.value)}>
                    <option value={-1}>تمام جلسات</option>
                    {lessons.map((lesson, i) => (
                        <option value={i}>{lesson.title}</option>
                    ))}
                </select>
                <select class="form-select col mx-1" onChange={(e) => setSelectedAzmoonIndex(e.target.value)}>
                    <option value={-1}>تمام آزمون ها</option>
                    {selectedLessonIndex != -1 && lessons[selectedLessonIndex]["azmoons"].map((lesson, i) => (
                        <option value={i}>{lesson.title}</option>
                    ))}
                </select>
                <select class="form-select col mx-1">
                    <option value={1}>نمرات</option>
                    <option value={2}>سوالات</option>
                </select>
                <button className="btn btn-primary col" onClick={() => btnShowClick()}>نمایش</button>
            </div>
            {isLoading == true &&
                <div className="vh-100">
                    <div class="spinner-grow text-primary position-absolute top-50 start-50" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
            {isLoading == false && <CanvasJSReact.CanvasJSChart key={widthChangeCount} options={options} />}
        </div>
    );
};

export default LessonChartComponent;



