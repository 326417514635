import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useState } from "react";
import courseService from "../../services/course.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LessonsListEnableComponent from "./CourseLessonsComponent";
import CourseSettingComponent from "./CourseSetting.component";
import { useLocation } from "react-router-dom";
import CourseStudentsComponent from "./CourseStudentsComponent";
import AllDontCheckAnswersOfCourseComponent from "./AllDontCheckAnswersOfCourseComponent";
import LessonChartComponent from "./LessonChartComponent";

const CoursePage = (props) => {
    const { state } = useLocation();
    const [courseId, setCourseId] = useState(false);

    useEffect(() => {
        setCourseId(state.courseId);
    }, []);


    return (
        <div className="row7">
            <Card>
                <Tabs>
                    <TabList>
                        <Tab>تکالیف ارسال شده</Tab>
                        <Tab>جلسات</Tab>
                        <Tab>هنرجویان</Tab>
                        <Tab>نمودارها</Tab>
                        <Tab>تنظیمات</Tab>
                    </TabList>

                    <TabPanel>
                        {courseId != -1 && courseId != false && <AllDontCheckAnswersOfCourseComponent courseId={courseId} />}
                    </TabPanel>
                    <TabPanel>
                        {courseId != -1 && courseId != false && <LessonsListEnableComponent courseId={courseId} />}
                    </TabPanel>
                    <TabPanel>
                        <br />
                        {courseId != -1 && courseId != false && <CourseStudentsComponent courseId={courseId} />}
                    </TabPanel>
                    <TabPanel>
                        <LessonChartComponent />
                    </TabPanel>
                    <TabPanel>
                        {courseId != -1 && courseId != false && <CourseSettingComponent courseId={courseId} />}
                    </TabPanel>
                </Tabs>
            </Card>
        </div>
    );
};

export default CoursePage;



