import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AcademyPage = (props) => {
    return (
        <div className="w-100">
            <Card>
                <Title>
                    تنظیمات آموزشگاه
                </Title>
                <Content>
                    <form id="demo-form2" data-parsley-validate="" class="form-horizontal form-label-left" novalidate="">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12 form-group">
                                <div class="form-group">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="academy-name">نام آموزشگاه
                                        <span class="required">*</span>
                                    </label>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" id="academy-name" required="required" class="form-control col-md-7 col-xs-12" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12 form-group">

                                <div class="form-group">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">نوع آموزشگاه
                                        <span class="required">*</span>
                                    </label>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <select class="form-control">
                                            <option>نوع آموزشگاه</option>
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12 form-group">
                                <div class="form-group">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">استان
                                        <span class="required">*</span>
                                    </label>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <select class="form-control">
                                            <option></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12 form-group">

                                <div class="form-group">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">شهر
                                        <span class="required">*</span>
                                    </label>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <select class="form-control">
                                            <option>انتخاب شهر</option>
                                            <option>گزینه اول</option>
                                            <option>گزینه دوم</option>
                                            <option>گزینه سوم</option>
                                            <option>گزینه چهارم</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>

            </Card>
        </div>
    );
};

export default AcademyPage;



