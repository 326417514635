import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import azmoonsService from "../../services/azmoons.service";


const AzmoonListComponent = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [azmoons, setAzmoons] = useState([]);
    const [lessonId, setLessonId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    const [clickedIdToRemove, setClickedIdToRemove] = useState(-1);

    useEffect(() => {
        setLessonId(props.lessonId);
        allAzmoons(props.lessonId);
    }, []);

    function allAzmoons(lessonId) {
        setIsLoading(true);
        azmoonsService.allAzmoonsOfLesson(lessonId).then(
            (response) => {
                setAzmoons(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function goTOAddEditAzmoon(azmoonId) {
        // alert(azmoonId);
        props.showAddEditPage(azmoonId);
    }

    function editButtonHandler(htmlContentId) {

    }

    function confirmDelete(htmlContentId) {
        setClickedIdToRemove(htmlContentId);
    }

    return (
        <div>
            <div className='table-responsive m-3'>
                <Card>
                    <div className="d-flex align-items-center">
                        <p className="flex-grow-1"></p>
                        <div className="flex-grow-1 text-center">
                            <button onClick={()=>props.showAddEditPage(-1)} type="button" class="btn btn-primary btn-sm" >آزمون جدید</button>
                        </div>
                        <div className="flex-grow-1 text-end">
                            {/* <button onClick={props.showAddEditPage} type="button" class="btn btn-success btn-sm">برگشت<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="white" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20z" /></svg></button> */}
                        </div>
                    </div>
                    <hr />
                    <table class="table table-hover text-center">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>عنوان</th>
                                <th>تعداد سوالات</th>
                                <th>مجموع امتیازات</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {azmoons.map((azmoon, i) => {
                                return <tr>
                                    <td onClick={() => goTOAddEditAzmoon(azmoon["id"])} style={{ cursor: 'pointer', width: '100px' }}>{i + 1}</td>
                                    <td onClick={() => goTOAddEditAzmoon(azmoon["id"])} style={{ cursor: 'pointer' }} >{azmoon["title"]}</td>
                                    <td onClick={() => goTOAddEditAzmoon(azmoon["id"])} style={{ cursor: 'pointer', width: '100px' }}>{azmoon["questionCount"]}</td>
                                    <td onClick={() => goTOAddEditAzmoon(azmoon["id"])} style={{ cursor: 'pointer', width: '100px' }}>{azmoon["score"]}</td>
                                    <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                        <div class="dropdown">
                                            <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={() => editButtonHandler(azmoon["id"])}><a class="dropdown-item">ویرایش</a></li>
                                                <li onClick={() => confirmDelete(azmoon["id"])}>حذف</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            })}

                            {Object.keys(azmoons).length === 0 && !isLoading &&
                                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                            }

                            {isLoading &&
                                <tr><td className="text-center" colSpan={6}>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td></tr>
                            }
                        </tbody>
                    </table>
                </Card>
            </div>
        </div>

    );
};

export default AzmoonListComponent;



