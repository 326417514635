import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import 'react-tabs/style/react-tabs.css';
import htmlContentService from '../../services/htmlContent.service';
import LessonEditor from './LessonEditor';
import LessonEditorComponent from './LessonEditorComponent';

const LessonContentComponent = (props) => {
    const [lesson, setLesson] = useState([]);
    const [htmlContent, setHtmlContent] = useState({});
    const [isInEditMode, setIsInEditMode] = useState(false);

    useEffect(() => {
        setLesson(props.lesson);
        setHtmlContent(props.lesson.htmlContents[0]);
        console.log(props.lesson.htmlContents[0]);
    }, []);

    function goToPreviewPage() {
        window.open('/lesson-preview/' + lesson.htmlContents[0]["id"], '_blank', 'noreferrer');
    }

    function goToSlidePage() {
        window.open('/lesson-slide/' + lesson.htmlContents[0]["id"], '_blank', 'noreferrer');
    }

    function handleEditButton() {
        setIsInEditMode(!isInEditMode);
    }

    function changeEditMode() {
        setIsInEditMode(false);
    }

    function updateHtmlContentAfterSave(html, css) {
        var htmlContent1 = htmlContent;
        htmlContent1["htmlText"] = html;
        htmlContent1["css"] = css;
        setHtmlContent(htmlContent1);
    }

    return (
        <div className=''>

            <div>
                <div className="text-center">
                    {!isInEditMode &&
                        < button onClick={() => handleEditButton()} type="button" className="btn btn-success btn-sm mx-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ویرایش">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                            </svg>
                        </button>
                    }
                    {isInEditMode &&
                        < button onClick={() => handleEditButton()} type="button" className="btn btn-danger btn-sm mx-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="بستن">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </button>}

                    <input onClick={() => goToPreviewPage()} type="button" className="btn btn-primary btn-sm mx-2" value="باز کردن"></input>
                    <input onClick={() => goToSlidePage()} type="button" className="btn btn-info btn-sm mx-2" value="نمایش اسلاید"></input>
                </div>

                {!isInEditMode &&
                    <div className="w-100" style={{ padding: '10px' }}>
                        <style>{htmlContent.css}</style>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: htmlContent.htmlText }} />
                    </div>
                }
            </div>


            {isInEditMode &&
                <LessonEditorComponent changeEditMode={changeEditMode} htmlContentId={htmlContent.id} updateHtmlContent = {updateHtmlContentAfterSave} />
            }

        </div >
    );
};

export default LessonContentComponent;



