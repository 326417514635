
import Content from '../../components/Layout/card/Content';
import Title from '../../components/Layout/card/Title';
import Card from '../../components/Layout/card/Card';

import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
// import academyCourseService from "../../services/academyCourses.service";
import Popup from "reactjs-popup";
import { useEffect, useRef, useState } from 'react';
import lessonService from '../../services/lesson.service';
import { toast } from 'react-toastify';


const LessonAddComponent = (props) => {
    const closeRef = useRef();
    const [bookId, setBookId] = useState(-1);
    const [lessonTitle, setLessonTitle] = useState("");

    useEffect(() => {
        // console.log(props.bookId); //dont remove this
        setBookId(props.bookId);
    }, []);

    function insertLessonToBook() {
        lessonService.insertLesson(lessonTitle, bookId).then(
            (response) => {
                props.getLessons(bookId);
                setLessonTitle("");
                closeRef.current.click();
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    function titleInputChange(e) {
        setLessonTitle(e.target.value);
    }

    return (
        <div>
            <button type="button" className="btn btn-primary btn-md me-2" data-bs-toggle="modal" data-bs-target="#lessonAddModal">
                {/* <iconify-icon icon="mdi:add-bold" width="25" height="25" style={{ color: "white" }}></iconify-icon> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                </svg>
            </button>
            {/* <input type="button" className="btn btn-primary btn-md ms-2" data-bs-toggle="modal" data-bs-target="#lessonAddModal" value="افزودن جلسه"></input> */}

            <div class="modal fade" id="lessonAddModal" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel1">افزودن جلسه</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="input-group">
                                <span class="input-group-text">عنوان</span>
                                <input type="text" className='form-control' value={lessonTitle} onChange={titleInputChange}></input>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>بستن</button>
                            <button type="button" class="btn btn-primary" onClick={() => insertLessonToBook()}>ذخیره</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonAddComponent;



