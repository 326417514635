import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AzmoonListComponent from "./AzmoonListComponent";
import AzmoonAddEditComponent from "./AzmoonAddEditComponent";


const AzmoonPage = (props) => {
    const [lessonId, setLessonId] = useState(-1);

    const [selectedAzmoonId, setSelectedAzmoonId] = useState(-1);
    const [azmoonListOrAzmoonAddEdit, setAzmoonListOrAzmoonAddEdit] = useState(true);

    useEffect(() => {
        setLessonId(props.lessonId);
        // alert(props.lessonId);
    }, []);

    function showAzmoonList() {
        setAzmoonListOrAzmoonAddEdit(true);
    }

    function showAddEditPage(azmoonId) {
        setSelectedAzmoonId(azmoonId);
        setAzmoonListOrAzmoonAddEdit(false);
    }

    return (
        <div>
            {azmoonListOrAzmoonAddEdit && lessonId != -1 &&
                <AzmoonListComponent lessonId={lessonId} showAddEditPage={(azmoonId)=>showAddEditPage(azmoonId)} />
            }
            {!azmoonListOrAzmoonAddEdit && lessonId != -1 && 
                <AzmoonAddEditComponent azmoonId = {selectedAzmoonId} lessonId={lessonId} showAzmoonList={showAddEditPage} />
            }
        </div>

    );
};

export default AzmoonPage;



