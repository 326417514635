import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const getHtmlContentById = (htmlContentId) => {
  return api.get('/Teacher/Content/GetByID/' + htmlContentId);
};

const getHtmlContentByIdForStudent = (htmlContentId,studentCourseCode) => {
  return api.get('/Student/Content/GetByID/' + htmlContentId + "/" + studentCourseCode);
};

const getSecondaryHtmlContentsOfLesson = (lessonId) => {
  return api.get('/Teacher/Content/GetSecondaryHtmlContentsOfLesson/' + lessonId);
};

const insert = (lessonId, htmlText, css, title) => {
  var data = {
      "title": title,
      "htmlText": htmlText,
      "css": css,
  };

  return api.post('/Teacher/Content/Insert/' + lessonId, data);
};

const updateHtmlContent = (htmlContentId, htmlText, css) => {
  var data = {
      "htmlText": htmlText,
      "css": css,
  };

  return api.put('/Teacher/Content/Update/' + htmlContentId, data);
};

const destroy = (htmlContentId) => {
  return api.delete('/Teacher/Content/Delete/' + htmlContentId);
};

export default {
  getHtmlContentById,
  getHtmlContentByIdForStudent,
  insert,
  destroy,
  updateHtmlContent,
  getSecondaryHtmlContentsOfLesson,
};
