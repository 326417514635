import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import academyStudentsService from '../../../services/academyStudents.service';
import { useLocation } from 'react-router-dom';
import courseStudentsService from '../../../services/courseStudents.service';


const SelectStudentModal = (props) => {
    // const { state } = useLocation();
    const [students, setStudents] = useState([]);
    const [courseId, setCourseId] = useState("");
    const [studentName, setStudentName] = useState("");
    const [studentFamily, setStudentFamily] = useState("");
    const [studentUsername, setStudentUsername] = useState("");
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        // getAcademyStudents();
        setCourseId(props.courseId);
        // console.log(props.courseId);
    }, []);

    function getAcademyStudents() {
        setIsSearch(true);
        academyStudentsService.allStudentsOfAcademy(studentName, studentFamily, studentUsername).then(
            (response) => {
                setStudents(response.data);
            },
            (error) => {

            }
        );
        setIsSearch(false);
    }

    function addStudentToCourse(studentId) {
        courseStudentsService.addStudentToCourse(courseId, studentId).then(
            (response) => {
                props.getAllStudentsOfCourse();
            },
            (error) => {

            }
        );
    }

    return (

        <div>
            {courseId !== -1 &&
                <button type="button" onClick={() => getAcademyStudents()} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    افزودن هنرآموز
                </button>
            }
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div style={{ display: 'flex' }}>
                                <input onChange={(e) => setStudentName(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام' />
                                <input onChange={(e) => setStudentFamily(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام خانوادگی' />
                                <input onChange={(e) => setStudentUsername(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام کاربری' />
                                <button type="button" class="btn btn-primary" onClick={() => getAcademyStudents()}>جستجو</button>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>نام</th>
                                        <th>نام خانوادگی</th>
                                        <th>نام کاربری</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {students.map((student, i) => {
                                        return <tr>
                                            <td>{i + 1}</td>
                                            <td>{student["name"]}</td>
                                            <td>{student["family"]}</td>
                                            <td>{student["mobile"]}</td>
                                            <td className='text-left'>
                                                <button type="button" onClick={() => addStudentToCourse(student["id"])} class="btn btn-success">انتخاب</button>
                                            </td>
                                        </tr>
                                    })}

                                    {Object.keys(students).length === 0 && !isSearch &&
                                        <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

        // <Popup trigger={courseId !== -1 && <button type="button" class="btn btn-primary">افزودن هنرآموز</button>} modal>
        //     <div style={{ padding: '20px' }}>
        //         <h4 className='text-center'>انتخاب دانشجو</h4>
        //         <hr />
        //         <div style={{ display: 'flex' }}>
        //             <input onChange={(e)=>setStudentName(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام' />
        //             <input onChange={(e)=>setStudentFamily(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام خانوادگی' />
        //             <input onChange={(e)=>setStudentUsername(e.target.value)} type="text" id="course-name" class="form-control" placeholder='نام کاربری' />
        //             <button type="button" class="btn btn-primary" onClick={() => getAcademyStudents()}>جستجو</button>
        //         </div>
        //         <hr />
        //         <table class="table">
        //             <thead>
        //                 <tr>
        //                     <th>#</th>
        //                     <th>نام</th>
        //                     <th>نام خانوادگی</th>
        //                     <th>نام کاربری</th>
        //                     <th></th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {students.map((student, i) => {
        //                     return <tr>
        //                         <td>{i + 1}</td>
        //                         <td>{student["name"]}</td>
        //                         <td>{student["family"]}</td>
        //                         <td>{student["mobile"]}</td>
        //                         <td className='text-left'>
        //                             <button type="button" onClick={()=> addStudentToCourse(student["id"])} class="btn btn-success">انتخاب</button>
        //                         </td>
        //                     </tr>
        //                 })}

        //                 {Object.keys(students).length === 0 && !isSearch &&
        //                     <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
        //                 }

        //             </tbody>
        //         </table>
        //     </div>
        // </Popup>
    );
};

export default SelectStudentModal;