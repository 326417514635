import axios from 'axios';
import Settings from '../Settings';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
    // baseURL: "http://localhost:5266/api", // our API base URL
});


api.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g., parse, transform)

        return response;
    },
    function (error) {

        try {
            if (error.response.status == 401) {
                localStorage.removeItem("user");
                window.location.reload();
                // navigate("/login");
                toast.error("از دوباره وارد شوید");
                //useDispatch(logout());
            }

            if (error.response.status == 403) {
                toast.error("دسترسی ندارید");
            }

            if (error.response.status == 404) {
                toast.error("صفحه یافت نشد");
            }

            if (error.response.data.message)
                toast.error(error.response.data.message);

        } catch (e) {
            toast.error("مشکلی پیش آمده. بعدا امتحان کنید")
        }
        return Promise.reject(error);
    }
);


export default api;
