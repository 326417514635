import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import academyTeachers from "../../services/academyTeachers.service";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import courseService from "../../services/course.service";

const MyCoursesPage = (props) => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAcademyTeachers();
    }, []);

    function getAcademyTeachers() {
        setIsLoading(true);
        courseService.allCoursesOfTeacher().then(
            (response) => {
                setIsLoading(false);
                setCourses(response.data);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function goToCoursePage(courseId) {
        navigate('/my-course', { state: { courseId: courseId } });
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>کلاس های من</p>

                    </div>
                </Title>
                <Content>
                        <div class="table-responsive">
                            <table class="table table-hover text-center">
                                <thead>
                                    <tr className="text-center">
                                        <th>#</th>
                                        <th className="text-center">نام دوره</th>
                                        <th className="text-center">وضعیت دوره</th>
                                        <th className="text-center">تعداد هنرآموزان</th>
                                        {/* <th className="text-center">درس اختصاص یافته</th> */}
                                        <th>امکانات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {courses.map((course, i) => {
                                        return <tr className="text-center" style={course["statusCode"] == 3 ? { backgroundColor: '#cfe3d4' } : {}}>
                                            <td>{i + 1}</td>
                                            <td onClick={() => goToCoursePage(course["id"])} style={{ cursor: 'pointer' }}>
                                                <p>{course["name"]}</p>
                                            </td>
                                            <td onClick={() => goToCoursePage(course["id"])} style={{ cursor: 'pointer' }}>
                                                {course["status"]}
                                            </td>
                                            <td onClick={() => goToCoursePage(course["id"])} style={{ cursor: 'pointer' }}>
                                                {course["studentsCount"]}
                                            </td>
                                            {/* <td onClick={() => goToCoursePage(course["id"])} style={{ cursor: 'pointer' }}>
                                                {course["book"]?.name}
                                            </td> */}
                                            <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                                <div class="dropdown">
                                                    <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a class="dropdown-item" href="#">حذف</a></li>
                                                    </ul>
                                                </div>

                                            </td>
                                        </tr>
                                    })}

                                    {Object.keys(courses).length === 0 && !isLoading &&
                                        <tr><td className="text-center" colSpan={6}>رکوردی یافت نشد</td></tr>
                                    }

                                    {isLoading &&
                                        <tr><td className="text-center" colSpan={6}>
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                </Content>

            </Card>
        </div>
    );
};

export default MyCoursesPage;



