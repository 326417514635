import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AzmoonListComponent from "./AzmoonListComponent";
import AzmoonAddEditComponent from "./AzmoonAddEditComponent";
import questionsService from "../../services/questions.service";
import { toast } from "react-toastify";
import booksService from "../../services/books.service";


const AddTeacherToBookComponent = (props) => {
    // const openRef = useRef();
    const closeRef = useRef();

    const [bookId, setBookId] = useState(-1);
    const [mobileToAdd, setMobileToAdd] = useState("");


    useEffect(() => {
        // alert( "2->" + props.bookId)
        setBookId(props.bookId);
        // setMobileToAdd(props.mobile);
        // if (props.bookId != undefined && props.bookId != -1) {
        //     openRef.current.click();
        // }
    }, []);

    function addTeacherToBook() {
        booksService.addTeacherToBook(bookId, mobileToAdd).then(
            (response) => {
                props.afterInsert(response.data.data);
                closeRef.current.click();
            },
            (error) => {
            }
        );
    }


    function btnAddHandler() {
        if (mobileToAdd == "") {
            toast.error("شماره موبایل را وارد کنید.");
            return
        }
        addTeacherToBook();
    }

    return (
        <div>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteQuestionModel1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                </svg>
            </button>

            <div class="modal fade" id="deleteQuestionModel1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">افزودن</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <input value={mobileToAdd} onChange={(e) => setMobileToAdd(e.target.value)} className="form-control" type="text" placeholder="شماره موبایل مربی" />
                        </div>
                        <div class="modal-footer">
                            <button onClick={() => setMobileToAdd("")} ref={closeRef} type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
                            <button onClick={() => btnAddHandler()} type="button" class="btn btn-primary">افزودن</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTeacherToBookComponent;



