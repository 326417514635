import Content from "../components/Layout/card/Content";
import Card from "../components/Layout/card/Card";
import Title from "../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import academyStudentsService from "../services/academyStudents.service";
import { useEffect, useState } from "react";

import { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

const ManagerHomePage = (props) => {
    const [latestStudents, setLatestStudents] = useState([]);
    const [cash, setCash] = useState(0);
    const [usedStorage, setUsedStorage] = useState(0);
    const [totalStorage, setTotalStorage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getLatestRegisteringStudents(1);
    }, [])

    function getLatestRegisteringStudents(pageNumber) {
        setLatestStudents([]);
        setIsLoading(true);
        academyStudentsService.getLatestRegisteringStudents(pageNumber).then(
            (response) => {
                setLatestStudents(response.data.students);
                setCash(ToRial(response.data.cash.toString()));
                setUsedStorage(response.data.usedStorage);
                setTotalStorage(response.data.totalStorage);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function ToRial(str) {
        str = str.replace(/\,/g, '');
        var objRegex = new RegExp('(-?[0-9]+)([0-9]{3})');

        while (objRegex.test(str)) {
            str = str.replace(objRegex, '$1,$2');
        }

        return str;
    }

    return (
        <div>

            <div className="row m-0 mb-2">
                <div className="col-md-3 mt-2">
                    <Card>
                        <Title>
                            <p className="my-1 text-center">موجودی حساب</p>
                        </Title>
                        <Content>
                            {isLoading == false &&
                                <p className="my-1 text-center fs-6">{cash} تومان</p>
                            }
                            {isLoading &&
                                <div class="spinner-border text-primary d-block m-auto" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }
                        </Content>
                    </Card>
                </div>
                <div className="col-md-3 mt-2">
                    <Card>
                        <Title>
                            <p className="my-1 text-center">فضای استفاده شده</p>
                        </Title>
                        <Content>
                            {isLoading == false &&
                                <div class="progress position-relative my-2" dir="ltr" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" title={"ظرفیت کل: " + totalStorage + " مگابایت" + "\n ظرفیت استفاده شده: " + usedStorage + " مگابایت"}>
                                    <div className="justify-content-center d-flex position-absolute w-100">{Math.round((100 / totalStorage) * usedStorage) + '%'}</div>
                                    <div class="progress-bar" style={{ width: (100 / totalStorage) * usedStorage + '%' }}></div>
                                </div>
                            }
                            {isLoading &&
                                <div class="spinner-border text-primary d-block m-auto" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            }
                        </Content>
                    </Card>
                </div>
            </div>

            <div className="row m-0">
                <div className="col-md-12">
                    <Card>
                        <Title>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>آخرین ثبت نام ها</p>
                            </div>
                        </Title>
                        <Content>
                            <div class="table-responsive">
                                <table class="table table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>نام و نام خانوادگی</th>
                                            <th>نام کاربری</th>
                                            <th>نام درس</th>
                                            <th>تاریخ ثبت نام</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {latestStudents.map((student, i) => {
                                            return <tr>
                                                <td>{i + 1}</td>
                                                <td>{student["name"] + " " + student["family"]}</td>
                                                <td>{student["mobile"]}</td>
                                                <td>{student["courseName"]}</td>
                                                <td>{new DateObject(student["dateTime"]).convert(persian, persian_fa).toString()}</td>
                                                <td><button type="button" className="btn btn-primary btn-sm">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M437.3 30L202.7 339.3L64 200.7l-64 64L213.3 478L512 94z" /></svg>
                                                </button></td>
                                            </tr>
                                        })}



                                        {Object.keys(latestStudents).length === 0 && !isLoading &&
                                            <tr><td className="text-center" colSpan={7}>رکوردی یافت نشد</td></tr>
                                        }

                                        {isLoading && Object.keys(latestStudents).length === 0 &&
                                            <tr><td className="text-center" colSpan={7}>
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </td></tr>
                                        }


                                    </tbody>
                                </table>
                                <nav>
                                    <ul class="pagination pagination-md justify-content-center">
                                        <li class="page-item" aria-current="page"><a class="page-link" href="javascript:;" onClick={() => getLatestRegisteringStudents(1)}>1</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" onClick={() => getLatestRegisteringStudents(2)}>2</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" onClick={() => getLatestRegisteringStudents(3)}>3</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </Content>

                    </Card>
                </div>
            </div>
        </div>
    );
};

export default ManagerHomePage;



