import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import academyService from "../../services/academy.service";

const AcademiesPage = (props) => {
    const [teachers, setTeachers] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        getAcademies();
      }, []);

    function getAcademies() {
        setIsSearch(true);
        academyService.allAcademies().then(
          (response) => {
            setTeachers(response.data);
          },
          (error) => {

          }
        );
        setIsSearch(false);
      }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div>
                        <p>لیست آموزشگاه ها</p>
                        {/* <Link to="/add-teacher" class="site_title">
                            <button type="button" class="btn btn-primary">افزودن مربی جدید</button>
                        </Link> */}

                    </div>
                </Title>
                <Content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>نام آموزشگاه</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teachers.map((teacher, i) => {
                                return <tr>
                                    <td>{i + 1}</td>
                                    <td>{teacher["name"]}</td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                                <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots">
                                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-lg-right">
                                                <li className="p-2"><a href="#"><i class="fa fa-pencil"></i> ویرایش </a></li>
                                                <li><a href="#"><i class="fa fa-trash-o"></i> حذف </a></li>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            })}

                            {Object.keys(teachers).length === 0 && !isSearch &&
                                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                            }
                            
                        </tbody>
                    </table>
                </Content>

            </Card>
        </div>
    );
};

export default AcademiesPage;



