import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import lessonService from '../../services/lesson.service';
import { toast } from 'react-toastify';
import LessonChartOfCoureseComponent from './LessonChartOfCoureseComponent';


const CourseLessonsComponent = (props) => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(-1);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [chaneStatusId, setChaneStatusId] = useState(-1);

  const [selectedLessonId, setSelectedLessonId] = useState(-1);

  const dialogRef = useRef();

  useEffect(() => {
    setCourseId(props.courseId);
    getLessonsOfCourse(props.courseId);
  }, []);


  function getLessonsOfCourse(courseId) {
    setIsLoading(true);
    lessonService.GetLessonsAndCourseLessons(courseId).then(
      (response) => {
        setLessons(response.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  function EnableLessonForCourse(lessonId) {
    lessonService.EnableLessonForCourse(courseId, lessonId).then(
      (response) => {
        getLessonsOfCourse(courseId);
      },
      (error) => {

      }
    );
  }

  function DisableLessonForCourse(lessonId) {
    lessonService.DisableLessonForCourse(courseId, lessonId).then(
      (response) => {
        getLessonsOfCourse(courseId);
      },
      (error) => {

      }
    );
  }

  function goToPreviewPage(htmlContentId) {
    window.open('/lesson-preview/' + htmlContentId, '_blank', 'noreferrer');
  }

  function showLessonChartHandler(lessonId) {
    setSelectedLessonId(lessonId);
    dialogRef.current?.click();
  }

  return (
    <div class="table-responsive">
      <table class="table table-hover text-center">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">عنوان</th>
            <th className="text-center">برچسب ها</th>
            <th className="text-center"></th>

          </tr>
        </thead>
        <tbody>
          {lessons.map((lesson, i) => {
            return <tr>
              <td>{i + 1}</td>
              <td onClick={() => goToPreviewPage(lesson["id"])}>{lesson["title"]}</td>
              <td onClick={() => goToPreviewPage(lesson["id"])}>{lesson["tags"]}</td>
              <td className='text-end'>
                {!lesson["enabledAt"] && <button onClick={() => EnableLessonForCourse(lesson["id"])} type='button' className='btn btn-primary btn-sm'>فعال کردن</button>}
                {/* {lesson["enabledAt"] && <button onClick={() => showLessonChartHandler(lesson["id"])} type='button' className='btn btn-success btn-sm me-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><g fill="none" stroke="white"><path stroke-linecap="round" stroke-linejoin="round" d="M8 10v6m4-4v4m4-8v8" /><rect width="18" height="16" x="3" y="4" rx="2" /></g></svg>
                  نمودار
                </button>} */}
                {lesson["enabledAt"] && <button onClick={() => DisableLessonForCourse(lesson["id"])} type='button' className='btn btn-danger btn-sm'>غیرفعال کردن</button>}
              </td>
            </tr>
          })}

          {Object.keys(lessons).length === 0 && isLoading == false &&
            <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
          }

          {isLoading &&
            <tr><td className="text-center" colSpan={15}>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td></tr>
          }

        </tbody>
      </table>

      {/* <div>
        <button ref={dialogRef} type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Launch demo modal
        </button>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <LessonChartOfCoureseComponent key={selectedLessonId} courseId={courseId} lessonId={selectedLessonId} />
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  )
}



export default CourseLessonsComponent;