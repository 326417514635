import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import lessonService from '../../services/lesson.service';
import api from '../../services/API';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import htmlContentService from '../../services/htmlContent.service';

const LessonSlidePage = (props) => {
    const { htmlContentId } = useParams();
    const [htmlContent, setHtmlContent] = useState([]);
    const [htmlContentSlides, setHtmlContentSlides] = useState([]);

    useEffect(() => {
        getHtmlContentById(htmlContentId);
    }, []);


    function getHtmlContentById(htmlContentId) {
        htmlContentService.getHtmlContentById(htmlContentId).then(
            (response) => {
                // let htmlText = response.data.htmlContent.htmlText.replace(/\n/g, "");
                let htmlText = response.data.htmlContent;
                setHtmlContent(htmlText);
                setHtmlContentSlides(htmlText.htmlText.replace(/\n/g, "").match(/<section .*?>(.*?)<\/section>/g));
                console.log(htmlText);
                // console.log(htmlText.match(/<section>(.*?)<\/section>/g));

                // var str = "<b>Bob</b>, I'm <b>20</b> years old, I like <b>programming</b>.";
                // console.log(str.match(/<b>(.*?)<\/b>/g));
            },
            (error) => {
            }
        );
    }

    return (
        <div className='w-100 vh-100 d-flex align-items-center'>
            <div className="w-100" style={{ padding: '10px' }}>
                <style>{htmlContent.css}</style>
                {/* {htmlContentSlides}
            {htmlContentSlides.map((htmlContentSlide, i) => {
                        return i;
                    })} */}

                <div id="carouselExampleIndicators" class="carousel slide">
                    {/* <div class="carousel-indicators">
                    {htmlContentSlides.map((htmlContentSlide, i) => {
                        return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} aria-label="Slide 3" className={"" + (i == 0 ? 'active' : '')}></button>;

                    })}
                </div> */}
                    <div class="carousel-inner">
                        {/* <div class="carousel-item active">
                        شروع
                    </div> */}
                        {htmlContentSlides.map((htmlContentSlide, i) => {
                            return <div className={"carousel-item " + (i == 0 ? 'active' : '')}>
                                <div dangerouslySetInnerHTML={{ __html: htmlContentSlide }} />
                                {/* {htmlContentSlide} */}
                            </div>
                        })}
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

            </div>
        </div>
    );
};

export default LessonSlidePage;



