import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import lessonService from '../../services/lesson.service';
import LessonAddComponent from './LessonAddComponent';
import { ReactSortable } from "react-sortablejs";
import { toast } from 'react-toastify';
import questionsService from '../../services/questions.service';

// interface ItemType {
//   id: number;
//   name: string;
// }

const OrderQuestionsComponent = (props) => {
  const closeRef = useRef();
  const [azmoonId, setAzmoonId] = useState(-1);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setQuestions(props.questions);
    setAzmoonId(props.azmoonId);
  }, []);


  function updateOrderToserver() {
    let result = questions.map(a => a.id);
    questionsService.ChangeOrderOfQuestions(azmoonId, result).then(
      (response) => {
        props.updateQuestions(questions);
        closeRef.current.click();
      },
      (error) => {
        // toast.error("با خطا مواجه شد");
      }
    );
  }

  function updateOrder() {
    let result = questions.map(a => a.id);
  }


  return (
    <div>
      <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#questionsListModal" >
        تغییر ترتیب
      </button>
      <div class="modal fade" id="questionsListModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
              <p></p>
              <button className='btn btn-primary' onClick={() => updateOrderToserver()}>ذخیره</button>
              {/* <button type="button" ref={closeRef} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">


              <div className='d-flex'>
                {/* <td>#</td> */}
                <td style={{ width: '45%', textAlign: 'center' }}>عنوان</td>
              </div>

              <ReactSortable multiDrag list={questions} setList={setQuestions} filter=".exclude" onEnd={() => updateOrder()}>
                {questions.map((lesson, i) => (
                  <div key={lesson["id"]} className='border d-flex align-items-center mb-2' style={{ backgroundColor: '#fff' }}>
                    {/* <div className='d-flex'> */}
                    <svg style={{ cursor: 'move' }} className='ps-3' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                    <td style={{ cursor: 'pointer' }} className='text-center py-3'>{lesson["htmlText"]}</td>
                    {/* </div> */}
                  </div>
                ))}
              </ReactSortable>

              {Object.keys(questions).length === 0 &&
                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
              }

              {isLoading &&
                <tr><td className="text-center" colSpan={6}>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td></tr>
              }
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary d-none" data-bs-dismiss="modal" ref={closeRef}>بستن</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}



export default OrderQuestionsComponent;