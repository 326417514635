import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QuestionAddEditComponent from "./QuestionAddEditComponent";
import azmoonsService from "../../services/azmoons.service";
import { toast } from "react-toastify";
import QuestionDeleteComponent from "./QuestionDeleteComponent";
import OrderQuestionsComponent from "./OrderQuestionsComponent";


const AzmoonAddEditComponent = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [lessonId, setLessonId] = useState(-1);
    const [questions, setQuestions] = useState([]);

    const [azmoonTitle, setAzmoonTitle] = useState("");

    const [azmoonMaxAllowedNum, setAzmoonMaxAllowedNum] = useState(1);
    const [isEnableAzmoonMaxAllowedNum, setIsEnableAzmoonMaxAllowedNum] = useState(false);

    const [azmoonCss, setAzmoonCss] = useState("");
    const [azmoonId, setAzmoonId] = useState(-1);
    // const [azmoonTimeInSecond, setAzmoonTimeInSecond] = useState(100);

    const [azmoonPeriodTimeMinute, setAzmoonPeriodTimeMinute] = useState(0);
    const [azmoonPeriodTimeHours, setAzmoonPeriodTimeHours] = useState(0);
    const [azmoonPeriodTimeDay, setAzmoonPeriodTimeDay] = useState(0);
    const [isEnableAzmoonPeriodTime, setIsEnableAzmoonPeriodTime] = useState(false);

    const [azmoonTimeMinute, setAzmoonTimeMinute] = useState(0);
    const [azmoonTimeHours, setAzmoonTimeHours] = useState(0);
    const [azmoonTimeDay, setAzmoonTimeDay] = useState(0);
    const [isEnableAzmoonTime, setIsEnableAzmoonTime] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [clickedIdToRemove, setClickedIdToRemove] = useState(-1);

    const [selectedQuestionIdToEdit, setSelectedQuestionIdToEdit] = useState(-1);

    useEffect(() => {
        setLessonId(props.lessonId);
        setAzmoonId(props.azmoonId);
        // alert(props.azmoonId);

        if (props.azmoonId != -1) {
            getAzmoonWithQuestions(props.azmoonId);
        }

    }, []);

    function getAzmoonWithQuestions(azmoonId) {
        setIsLoading(true);
        azmoonsService.getAzmoonById(azmoonId).then(
            (response) => {
                setAzmoonId(response.data.id);
                setAzmoonTitle(response.data.title);
                setAzmoonCss(response.data.css);
                setQuestions(response.data.questions);
                setIsLoading(false);

                /////////// اعمال time /////////////////////
                setAzmoonPeriodTimeDay(parseInt(response.data.periodTime / 60 / 24))
                setAzmoonPeriodTimeHours(parseInt((response.data.periodTime / 60) % 24))
                setAzmoonPeriodTimeMinute(parseInt((response.data.periodTime % 60) % 24))
                if (response.data.periodTime == 0)
                    setIsEnableAzmoonPeriodTime(false);
                else
                    setIsEnableAzmoonPeriodTime(true);


                /////////// اعمال period time /////////////////////
                setAzmoonTimeDay(parseInt(response.data.time / 60 / 24))
                setAzmoonTimeHours(parseInt((response.data.time / 60) % 24))
                setAzmoonTimeMinute(parseInt((response.data.time % 60) % 24))
                if (response.data.time == 0)
                    setIsEnableAzmoonTime(false);
                else
                    setIsEnableAzmoonTime(true);

                /////////اعمال max allowed num//////////////////////
                setAzmoonMaxAllowedNum(response.data.maxAllowedNum);
                if (response.data.maxAllowedNum == 0)
                    setIsEnableAzmoonMaxAllowedNum(false);
                else
                    setIsEnableAzmoonMaxAllowedNum(true);

            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function insertAzmoon() {
        let localAzmoonMaxAllowedNum = checkAzmoonMaxAllowedNum();
        let periodTime = checkAzmoonPeriodTime();
        let time = checkAzmoonTime();

        azmoonsService.insertAzmoon(azmoonTitle, azmoonCss, localAzmoonMaxAllowedNum, periodTime, time, lessonId).then(
            (response) => {
                setAzmoonId(response.data.id);
                setAzmoonTitle(response.data.title);
            },
            (error) => {

            }
        );
    }

    function updateAzmoon() {
        let localAzmoonMaxAllowedNum = checkAzmoonMaxAllowedNum();
        let periodTime = checkAzmoonPeriodTime();
        let time = checkAzmoonTime();

        azmoonsService.updateAzmoon(azmoonId, azmoonTitle, azmoonCss, localAzmoonMaxAllowedNum, periodTime, time, lessonId).then(
            (response) => {
                setAzmoonId(response.data.id);
                setAzmoonTitle(response.data.title);
                toast.success("بروزرسانی شد.");
            },
            (error) => {

            }
        );
    }

    function saveAzmoonButtonHandler() {
        if (azmoonId == -1)
            insertAzmoon();
        else
            updateAzmoon();

    }

    function checkAzmoonMaxAllowedNum() {
        //اگر حداکثر دفعات مجاز برای ثبت پاسخ غیرفعال باشد
        let localAzmoonMaxAllowedNum = azmoonMaxAllowedNum;
        if (isEnableAzmoonMaxAllowedNum == false) {
            setAzmoonMaxAllowedNum(0);
            localAzmoonMaxAllowedNum = 0;
        }
        return localAzmoonMaxAllowedNum;
    }

    function checkAzmoonPeriodTime() {
        //اگر بازه زمانی آزمون غیرفعال باشد
        let periodTime = ((parseInt(azmoonPeriodTimeHours) * 60) + (parseInt(azmoonPeriodTimeDay) * 60 * 24)) + parseInt(azmoonPeriodTimeMinute)
        if (isEnableAzmoonPeriodTime == false) {
            setAzmoonPeriodTimeDay(0);
            setAzmoonPeriodTimeHours(0);
            setAzmoonPeriodTimeMinute(0);
            periodTime = 0;
        }
        return periodTime;
    }

    function checkAzmoonTime() {
        //اگر زمان آزمون غیرفعال باشد
        let time = ((parseInt(azmoonTimeHours) * 60) + (parseInt(azmoonTimeDay) * 60 * 24)) + parseInt(azmoonTimeMinute)
        if (isEnableAzmoonTime == false) {
            setAzmoonTimeDay(0);
            setAzmoonTimeHours(0);
            setAzmoonTimeMinute(0);
            time = 0;
        }
        return time;
    }

    function editButtonHandler(questionId) {
        // setEditQuestionDialog(false);
        setSelectedQuestionIdToEdit(questionId);
        // setEditQuestionDialog(true);
    }

    function confirmDelete(questionId) {
        setClickedIdToRemove(questionId);
    }

    function afterCloseQuestionAddEdit() {
        setSelectedQuestionIdToEdit(-1);
    }

    function getAnswerTypeDescription(answertType) {
        if (answertType == 1) {
            return "تشریحی";
        }
        else if (answertType == 2) {
            return "فایل";
        }
        else if (answertType == 3) {
            return "چند گزینه ای";
        }
        else if (answertType == 4) {
            return "عدد";
        }
    }

    function updateQuestions(questions) {
        setQuestions(questions);
    }

    // function handleChangeMaxAllowedNum(e) {
    //     let isChecked = e.target.checked;

    // }

    return (
        // <div>

        // </div>
        <div>
            <Card>
                <div className="d-flex align-items-center">
                    <p className="flex-grow-1"></p>
                    {/* <h6 className="flex-grow-1 text-center">مشخصات آزمون</h6> */}
                    <div className="flex-grow-1 text-end">
                        {/* <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">افزودن سوال</button> */}
                    </div>
                </div>
                {/* <hr className="mt-1"/> */}
                <div className="row">
                    <div className="col-md-6 text-center">
                        <div class="form-floating mb-3">
                            <input value={azmoonTitle} onChange={(e) => setAzmoonTitle(e.target.value)} className="form-control" type="text" placeholder="عنوان آزمون" />
                            <label for="floatingInput">عنوان آزمون</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="form-floating">
                            <textarea value={azmoonCss} onChange={(e) => setAzmoonCss(e.target.value)} class="form-control" dir="ltr" id="exampleFormControlTextarea1" rows="3" placeholder="کد Css"></textarea>
                            <label for="floatingTextarea">کد Css</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-sm-2">
                    <div className="col-md-6 text-center d-flex">
                        <div class="form-check">
                            <input checked={isEnableAzmoonMaxAllowedNum} onClick={(e) => setIsEnableAzmoonMaxAllowedNum(e.target.checked)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                        <div class="form-floating mb-3 flex-grow-1">
                            <input disabled={isEnableAzmoonMaxAllowedNum == true ? false : true} value={azmoonMaxAllowedNum} onChange={(e) => setAzmoonMaxAllowedNum(e.target.value)} className="form-control" type="number" placeholder="حداکثر دفعات مجاز برای آزمون" />
                            <label for="floatingInput">حداکثر دفعات مجاز برای ثبت پاسخ</label>
                        </div>
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>
                <div className="row mt-sm-2">
                    <div className="col-md-6">
                        <p className="m-0 text-center">مدت زمان آزمون</p>
                        <div className="d-flex">
                            <div class="form-check">
                                <input checked={isEnableAzmoonTime} onClick={(e) => setIsEnableAzmoonTime(e.target.checked)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="d-flex">
                                <div class="form-floating mb-3 ms-2">
                                    <input disabled={isEnableAzmoonTime == true ? false : true} value={azmoonTimeDay} onChange={(e) => setAzmoonTimeDay(e.target.value)} className="form-control text-center" type="number" placeholder="روز" />
                                    <label for="floatingInput">روز</label>
                                </div>
                                <div class="form-floating mb-3 ms-2">
                                    <input disabled={isEnableAzmoonTime == true ? false : true} value={azmoonTimeHours} onChange={(e) => setAzmoonTimeHours(e.target.value)} className="form-control text-center" type="number" placeholder="ساعت" />
                                    <label for="floatingInput">ساعت</label>
                                </div>
                                <div class="form-floating mb-3 ms-2">
                                    <input disabled={isEnableAzmoonTime == true ? false : true} value={azmoonTimeMinute} onChange={(e) => setAzmoonTimeMinute(e.target.value)} className="form-control text-center" type="number" placeholder="دقیقه" />
                                    <label for="floatingInput">دقیقه</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className="m-0 text-center">مدت فعال بودن آزمون بعد از فعال شدن درس</p>
                        <div className="d-flex">
                            <div class="form-check">
                                <input checked={isEnableAzmoonPeriodTime} onClick={(e) => setIsEnableAzmoonPeriodTime(e.target.checked)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div class="form-floating mb-3 ms-2">
                                <input disabled={isEnableAzmoonPeriodTime == true ? false : true} value={azmoonPeriodTimeDay} onChange={(e) => setAzmoonPeriodTimeDay(e.target.value)} className="form-control text-center" type="number" placeholder="روز" />
                                <label for="floatingInput">روز</label>
                            </div>
                            <div class="form-floating mb-3 ms-2">
                                <input disabled={isEnableAzmoonPeriodTime == true ? false : true} value={azmoonPeriodTimeHours} onChange={(e) => setAzmoonPeriodTimeHours(e.target.value)} className="form-control text-center" type="number" placeholder="ساعت" />
                                <label for="floatingInput">ساعت</label>
                            </div>
                            <div class="form-floating mb-3 ms-2">
                                <input disabled={isEnableAzmoonPeriodTime == true ? false : true} value={azmoonPeriodTimeMinute} onChange={(e) => setAzmoonPeriodTimeMinute(e.target.value)} className="form-control text-center" type="number" placeholder="دقیقه" />
                                <label for="floatingInput">دقیقه</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button onClick={saveAzmoonButtonHandler} className="btn btn-primary btn-sm mt-2 d-block mx-2">ذخیره</button>
                    <button onClick={() => props.showAzmoonList()} className="btn btn-success btn-sm mt-2 d-block mx-2">بستن</button>
                </div>
            </Card>
            <br />
            <br />
            <Card>
                <div className="d-flex align-items-center justify-content-between">
                    {azmoonId != -1 && questions.length > 0 && <OrderQuestionsComponent questions={questions} azmoonId={azmoonId} updateQuestions={updateQuestions} />}
                    <h6 className="text-center"> لیست سوالات</h6>
                    <div className="d-flex">
                        {azmoonId != -1 && <QuestionAddEditComponent key={selectedQuestionIdToEdit} azmoonId={azmoonId} questionId={selectedQuestionIdToEdit} getAzmoonWithQuestions={getAzmoonWithQuestions} afterCloseQuestionAddEdit={afterCloseQuestionAddEdit} />}
                    </div>
                </div>
                <hr />
                <div className='table-responsive m-3'>
                    <table class="table table-hover text-center">

                        <thead>
                            <tr>
                                <th>#</th>
                                <th>سوال</th>
                                <th>نوع جواب</th>
                                <th>امتیاز</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.map((question, i) => {
                                return <tr>
                                    <td onClick={() => editButtonHandler(question["id"])} style={{ cursor: 'pointer', width: '100px' }}>{question["id"]}</td>
                                    <td onClick={() => editButtonHandler(question["id"])} style={{ cursor: 'pointer' }} >{question["htmlText"]}</td>
                                    <td onClick={() => editButtonHandler(question["id"])} style={{ cursor: 'pointer' }} >{getAnswerTypeDescription(question["answertType"])}</td>
                                    <td onClick={() => editButtonHandler(question["id"])} style={{ cursor: 'pointer' }} >{question["score"]}</td>
                                    <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                        <div class="dropdown">
                                            <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={() => confirmDelete(question["id"])}><a class="dropdown-item">حذف</a></li>
                                                {/* <li onClick={() => confirmDelete(question["id"])}>حذف</li> */}
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            })}

                            {Object.keys(questions).length === 0 && !isLoading &&
                                <tr><td className="text-center" colSpan={5}>سوالی یافت نشد</td></tr>
                            }

                            {isLoading &&
                                <tr><td className="text-center" colSpan={6}>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td></tr>
                            }
                        </tbody>
                    </table>

                </div>
            </Card>
            <QuestionDeleteComponent key={clickedIdToRemove} azmoonId={azmoonId} getAzmoonWithQuestions={getAzmoonWithQuestions} questionId={clickedIdToRemove} />
        </div>

    );
};

export default AzmoonAddEditComponent;



