import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";


const getLessonChartOfLesson = (courseId, lessonId) => {
  return api.get('/Teacher/Chart/GetLessonChartOfCourse/' + courseId + "/" + lessonId);
};

const getAzmoonChartOfLesson = (courseId, azmoonId) => {
  return api.get('/Teacher/Chart/GetAzmoonChartOfCourse/' + courseId + "/" + azmoonId);
};

const getChartOfCourse = (courseId) => {
  return api.get('/Teacher/Chart/GetChartOfCourse/' + courseId);
};

export default {
  getChartOfCourse,
  getLessonChartOfLesson,
  getAzmoonChartOfLesson,
};
