import { Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { clearMessage } from "./actions/message";
import { createBrowserHistory } from "history";
import loadScripts from "./helpers/loadScripts"
import loadCss from "./helpers/loadCss"
import PrivateRoutes from "./other-functions";
import { AdminPanelSettings } from "./components/Layout/AdminPanelSettings";
import HomePage from "./pages/HomePage";
import AcademyPage from "./pages/manager/AcademyPage";
import AddTeacherPage from "./pages/manager/AddTeacherPage";
import AcademyRegisterPage from "./pages/manager/AcademyRegisterPage";
// import AcademyStudentsPage from "./pages/manager/StudentsPage";
import AcademiesPage from "./pages/admin/AcademiesPage";
import UsersPage from "./pages/manager/UsersPage";
import AcademyCoursesPage from "./pages/manager/AcademyCoursesPage";
import AddCoursePage from "./pages/manager/AddCoursePage";
import AcademyCoursePage from "./pages/manager/AcademyCoursePage";
import MyCoursesPage from "./pages/teacher/MyCoursesPage";
import CoursePage from "./pages/teacher/CoursePage";
import AddStudentPage from "./pages/manager/AddStudentPage";
import AddBookPage from "./pages/teacher/AddBookPage";
import BooksListPage from "./pages/teacher/BooksListPage";
import LessonEditor from "./pages/teacher/LessonEditor";
import AdminPrivateRoutes from "./other-functions";
import BlankPrivateRoutes from "./BlankPrivateRoutes";
import LessonPreview from "./pages/teacher/LessonPreview";
import LessonPage from "./pages/teacher/LessonPage";
import LessonViewerForStudentPage from "./pages/student/LessonViewerForStudentPage";
import ImageGalleryPage from "./pages/teacher/ImageGalleryPage";
import RegisterStudentInCourse from "./pages/student/RegisterStudentInCourse";
import ShowCourseByCourseCode from "./pages/student/ShowCourseByCourseCode";
import StudentHomePage from "./pages/student/StudentHomePage";
import LessonSlidePage from "./pages/teacher/LessonSlidePage";
import WebsitePage from "./pages/manager/WebsitePage";
import BlankPublicRoutes from "./BlankPublicRoutes";
import StudentPanelPage from "./pages/manager/StudentPanelPage";
import ProfilePage from "./pages/manager/ProfilePage";

const App = () => {
  // add javascript links 
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  useEffect(() => { loadScripts(AdminPanelSettings.javascript_lists, function () { }); history.listen((location) => { dispatch(clearMessage()); }); }, [dispatch]);
  // add body classes
  for (let i = 0; i < AdminPanelSettings.body_classes.length; i++) {
    document.body.classList.add(AdminPanelSettings.body_classes[i]);
  }
  // add css links
  for (let i = 0; i < AdminPanelSettings.css_list.length; i++) {
    loadCss(AdminPanelSettings.css_list[i]);
  }

  return (

    <Routes>
      <Route path='/:academyUniqueCode/login' element={<Login />} />
      <Route path='/academy-rgister' element={<AcademyRegisterPage />} />
      <Route exact path='/sc/:courseRegisterationCode' element={<ShowCourseByCourseCode />} />
      <Route exact path='/registerStudent/:courseRegisterationCode' element={<RegisterStudentInCourse />} />
      <Route exact path='/course/:studentCourseCode' element={<StudentHomePage />} />
      <Route exact path='/view/:studentCourseCode/:lessonId' element={<LessonViewerForStudentPage />} />

      <Route element={<AdminPrivateRoutes />}>
        <Route path='/' element={<HomePage />} />
        <Route path='/academy' element={<AcademyPage />} />
        <Route path='/academy-courses' element={<AcademyCoursesPage />} />
        <Route path='/academy-course' element={<AcademyCoursesPage />} />
        <Route path='/academies' element={<AcademiesPage />} />
        <Route path='/users' element={<UsersPage />} />
        {/* <Route path='/students' element={<AcademyStudentsPage />} /> */}
        <Route path='/add-teacher' element={<AddTeacherPage />} />
        <Route path='/add-course' element={<AddCoursePage />} />
        <Route path='/add-student' element={<AddStudentPage />} />
        <Route path='/academy-site' element={<WebsitePage />} />
        <Route path='/student-panel' element={<StudentPanelPage />} />

        {/* teacher */}
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/books' element={<BooksListPage />} />
        <Route path='/add-book' element={<AddBookPage />} />
        <Route path='/my-courses' element={<MyCoursesPage />} />
        <Route path='/my-course' element={<CoursePage />} />
        <Route path='/lesson' element={<LessonPage />} />
        <Route path='/lesson-editor' element={<LessonEditor />} />
        <Route path='/image-gallery' element={<ImageGalleryPage />} />
      </Route>

      <Route element={<BlankPrivateRoutes />}>
        <Route exact path='/lesson-preview/:htmlContentId' element={<LessonPreview />} />
        <Route exact path='/lesson-slide/:htmlContentId' element={<LessonSlidePage />} />
        {/* <Route exact path='/sc/:courseRegisterationCode' element={<ShowCourseByCourseCode />} /> */}
      </Route>



    </Routes>

  );
}


export default App;