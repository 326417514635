import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const updateLesson = (lessonId, title, tags, order) => {
  var data = {
    "ID": lessonId,
    "title": title,
    "tags": tags,
    "order": order,
  };

  return api.post('/Teacher/Lesson/UpdateLesson', data);
};

const insertLesson = (title, bookId) => {
  var data = {
    "title": title,
  };

  return api.post('/Teacher/Lesson/InsertLesson/' + bookId, data);
};

const getLessonByIdWithHtmlContent = (lessonId) => {
  return api.get('/Teacher/Lesson/GetLessonById/' + lessonId);
};

const remove = (lessonId) => {
  return api.delete('/Teacher/Lesson/Delete/' + lessonId);
};

const ChnageOrderOfLessons = (bookId, lessonIds) => {
  return api.post('/Teacher/Lesson/ChnageOrderOfLessons/' + bookId, lessonIds);
};

const GetLessonByIdWithHtmlContents = (lessonId) => {
  return api.get('/Teacher/Lesson/GetLessonByIdWithHtmls/' + lessonId);
};

const GetLessonsAndCourseLessons = (courseId) => {
  return api.get('/Teacher/Lesson/GetLessonsAndCourseLessons/' + courseId);
};

const EnableLessonForCourse = (courseId, lessonId) => {
  return api.get('/Teacher/Lesson/EnableLessonForCourse/' + courseId + "/" + lessonId);
};

const DisableLessonForCourse = (courseId, lessonId) => {
  return api.get('/Teacher/Lesson/DisableLessonForCourse/' + courseId + "/" + lessonId);
};

const getLessonsOfStudentInCourse = (studentId, courseId) => {
  return api.get('/Teacher/Lesson/GetLessonsOfStudent/' + studentId + "/" + courseId);
};

const getAllLessonsOfCourseWithAzmoons = (courseId) => {
  return api.get('/Teacher/Lesson/GetAllLessonsWithAzmoons/' + courseId);
};

const EnableLessonForStudent = (studentId, courseId, lessonId) => {
  return api.get('/Teacher/Lesson/EnableLessonForStudent/' + studentId + "/" + courseId + "/" + lessonId);
};

const DisableLessonForStudent = (studentId, courseId, lessonId) => {
  return api.get('/Teacher/Lesson/DisableLessonForStudent/' + studentId + "/" + courseId + "/" + lessonId);
};

export default {
  remove,
  updateLesson,
  EnableLessonForCourse,
  DisableLessonForCourse,
  insertLesson,
  getAllLessonsOfCourseWithAzmoons,
  GetLessonsAndCourseLessons,
  getLessonByIdWithHtmlContent,
  GetLessonByIdWithHtmlContents,
  getLessonsOfStudentInCourse,
  EnableLessonForStudent,
  DisableLessonForStudent,
  ChnageOrderOfLessons,
};
