import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

// const destroy = (bookId) => {
//   return api.delete('/Teacher/Book/Delete/' + bookId);
// };

const getWebSite = () => {
  return api.get('/Manager/WebSite/Get');
};

const update = (id,FavIconUri) => {
  var data = {
    "id": id,
    "FavIconUri": FavIconUri,
  };

  return api.put('/Manager/WebSite/Update', data);
};

export default {
  getWebSite,
  update,
};
