import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import academyTeachers from "../../services/academyTeachers.service";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import teacherBooksService from "../../services/teacherBooks.service";
import booksService from "../../services/books.service";

const BooksListPage = (props) => {
    const navigate = useNavigate();
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getBooksOfTeacher();
    }, []);

    function getBooksOfTeacher() {
        setIsLoading(true);
        teacherBooksService.allBooksOfTeacher().then(
            (response) => {
                setBooks(response.data);
                setIsLoading(false);

            },
            (error) => {
                setIsLoading(false);

            }
        );
    }

    function deleteBook(bookId) {
        booksService.destroy(bookId).then(
            (response) => {
                getBooksOfTeacher();
            },
            (error) => {

            }
        );
    }

    function goToLessonPage(bookId) {
        navigate('/lesson', { state: { bookId: bookId } });
    }

    function goToAddBookPage(bookId) {
        navigate('/add-book', { state: { bookId: bookId } });
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>لیست درس ها</p>

                        <div class="dropdown">
                            <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            </button>
                            <ul class="dropdown-menu bg-primary p-0" aria-labelledby="dropdownMenuButton1">
                                <li><button type="button" class="btn btn-primary w-100" onClick={() => goToAddBookPage(-1)}>افزودن درس جدید</button></li>
                                <li><button type="button" class="btn btn-primary w-100" onClick={() => goToAddBookPage(-1)}>فروشگاه</button></li>
                            </ul>
                        </div>



                    </div>
                </Title>
                <Content>
                    <div class="table-responsive">
                        <table class="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">نام</th>
                                    <th className="text-center">تعداد جلسات</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {books.map((book, i) => {
                                    return <tr style={{ cursor: 'pointer' }} >
                                        <td onClick={() => goToLessonPage(book["id"])}>{i + 1}</td>
                                        <td onClick={() => goToLessonPage(book["id"])}>{book["name"]}</td>
                                        <td onClick={() => goToLessonPage(book["id"])}>{book["lessonCount"]}</td>
                                        <td class="dropdown">
                                            <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1">
                                                <li className="list-group-item list-group-item-action" onClick={() => goToAddBookPage(book["id"])}>
                                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                    </svg>
                                                    <span className="ms-2">ویرایش</span>
                                                </li>
                                                <li className="list-group-item list-group-item-action" onClick={() => deleteBook(book["id"])}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    <span className="ms-2">حذف</span>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                })}

                                {Object.keys(books).length === 0 && !isLoading &&
                                    <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                                }

                                {isLoading &&
                                    <tr><td className="text-center" colSpan={6}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </Content>

            </Card>
        </div>
    );
};

export default BooksListPage;



