
import Content from '../../components/Layout/card/Content';
import Title from '../../components/Layout/card/Title';
import Card from '../../components/Layout/card/Card';

import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
// import academyCourseService from "../../services/academyCourses.service";
import Popup from "reactjs-popup";
import { useEffect, useState } from 'react';
import lessonService from '../../services/lesson.service';
import { toast } from 'react-toastify';
import MediaGalleryComponent from './MediaGalleryComponent';


const ImageGalleryPage = (props) => {

    useEffect(() => {

    }, []);


    return (
        <div>
            <MediaGalleryComponent/>
        </div>
    )
};

export default ImageGalleryPage;



