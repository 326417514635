import { Editor } from "@tinymce/tinymce-react";

import Card from "../../components/Layout/card/Card";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import htmlContentService from "../../services/htmlContent.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";


const LessonEditorComponent = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const [bookId, setBookId] = useState(-1);
    const [htmlContent, setHtmlContent] = useState({});
    const [html, setHtml] = useState("");
    const [css, setCss] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // setBookId(location.state.bookId);
        // alert(props.htmlContentId);
        getHtmlContentById(props.htmlContentId);
    }, []);



    function getHtmlContentById(htmlContentId) {
        htmlContentService.getHtmlContentById(htmlContentId).then(
            (response) => {
                setHtmlContent(response.data.htmlContent);
                console.log(response.data.htmlContent);
                // setHtmlContent(response.data.htmlContents[0]);
                setHtml(response.data.htmlContent.htmlText);
                setCss(response.data.htmlContent.css);
            },
            (error) => {
            }
        );
    }

    function saveHtmlContent(htmlContentId, html, css) {
        htmlContentService.updateHtmlContent(htmlContentId, html, css).then(
            (response) => {
                props.updateHtmlContent(html, css);
                setIsSaving(false);
            },
            (error) => {
                setIsSaving(false);
            }
        );
    }

    function goToPreviewPage() {
        window.open('/lesson-preview/' + htmlContent.id, '_blank', 'noreferrer');
    }

    // function goToLessonPage() {
    //     navigate('/lesson', { state: { lessonId: htmlContent.lessonID } });
    // }

    function goToLessonPage() {
        props.changeEditMode(false);
    }

    function handleHtmlChange(e) {
        setHtml(e.target.value);
    }

    function handleCssChange(e) {
        setCss(e.target.value);
    }

    function handleSave() {
        setIsSaving(true);
        saveHtmlContent(htmlContent.id, html, css);
    }

    return (
        <div style={{ height: "100vh" }}>
            <div className="mb-3">
                {/* <input onClick={() => goToPreviewPage()} type="button" className="btn btn-primary btn-sm mx-2" value="پیشنمایش"></input> */}
                {/* <a onClick={() => handleSave()} type="button" className="btn btn-sm btn-primary">
                    <span className="text-white">ذخیره
                        {isSaving && <div style={{ width: '18px', height: '18px' }} class="spinner-border" role="status" />}
                    </span>
                </a> */}
                {/* <input onClick={() => goToLessonPage()} type="button" className="btn btn-danger btn-sm mx-2" value="برگشت"></input> */}
            </div>
            <Tabs>
                <TabList>
                    {/* <Tab>پیشنمایش</Tab> */}
                    <Tab>کد Html</Tab>
                    <Tab>کد css</Tab>

                    <a onClick={() => handleSave()} type="button" className="btn btn-sm btn-primary ms-3">
                        <span className="text-white">ذخیره
                            {isSaving && <div style={{ width: '18px', height: '18px' }} class="spinner-border" role="status" />}
                        </span>
                    </a>

                </TabList>

                {/* <TabPanel>
                    <div className="w-100" style={{ backgroundColor: '#FAF4F3' }}>
                        <style>{css}</style>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </TabPanel> */}
                <TabPanel>
                    <textarea onChange={handleHtmlChange} value={html} rows="23" class="form-control" id="exampleFormControlTextarea1" dir="ltr"></textarea>
                </TabPanel>
                <TabPanel>
                    <textarea onChange={handleCssChange} value={css} rows="23" class="form-control" id="exampleFormControlTextarea1" dir="ltr"></textarea>
                </TabPanel>
            </Tabs>
        </div>

    );
};

export default LessonEditorComponent;



