import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const allAcademies = () => {
  return api.get('/Admin/Academy/AllAcademies');
};

export default {
  allAcademies,
};
