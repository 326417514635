import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import academyTeachersService from "../../services/academyTeachers.service";
import authService from "../../services/auth.service";
import { toast } from "react-toastify";

const ProfilePage = (props) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [family, setFamily] = useState("");
    const [mobile, setMobile] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        getProfile();
    }, []);

    function getProfile() {
        setIsSaving(true);
        authService.getProfile().then(
            (response) => {
                setName(response.data.name);
                setFamily(response.data.family);
                setMobile(response.data.mobile);
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsSaving(false);
    }

    function updateProfile() {
        setIsSaving(true);
        authService.updateCurrentUser(name, family).then(
            (response) => {
                setName(response.data.name);
                setFamily(response.data.family);
                setMobile(response.data.mobile);
                toast.success("با موفقیت تغییر کرد.")
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsSaving(false);
    }

    function changePassword() {
        if (newPassword != repeatNewPassword) {
            toast.info("پسورد با هم منطبق نیست");
            return;
        }

        setIsSaving(true);
        authService.changePassword(currentPassword, newPassword).then(
            (response) => {
                toast.success("با موفقیت تغییر کرد.")
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsSaving(false);
    }

    return (
        <div className="row">
            <div className="col-md-6 mt-3">
                <Card>
                    <Title>
                        <div>
                            <p>پروفایل</p>
                        </div>
                    </Title>
                    <Content>

                        <div class="form-label-center ">
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">نام<span class="required">*</span></label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="first-name" required="required" class="form-control" />
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">نام خانوادگی <span class="required">*</span></label>
                                <input value={family} onChange={(e) => setFamily(e.target.value)} type="text" id="last-name" name="last-name" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="mobile">شماره موبایل <span class="required">*</span></label>
                                <input value={mobile} disabled type="text" id="mobile" name="mobile" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <br />
                            <div class="form-group">
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn btn-success me-3" onClick={() => updateProfile()} >ثبت</button>
                                    {/* <Link to="/teachers"><button type="submit" class="btn btn-primary">انصراف</button></Link> */}
                                </div>
                            </div>
                        </div>
                    </Content>
                </Card>
            </div>
            <div className="col-md-6 mt-3">
                <Card>
                    <Title>
                        <div>
                            <p>تغییر رمز عبور</p>
                        </div>
                    </Title>
                    <Content>
                        <div class="form-label-center ">
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="current-password">رمز عبور فعلی<span class="required">*</span></label>
                                <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="text" id="current-password" name="current-password" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="new-password">رمز عبور جدید<span class="required">*</span></label>
                                <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="text" id="new-password" name="new-password" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="repeat-new-password">تکرار رمز عبور جدید<span class="required">*</span></label>
                                <input value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} type="text" id="repeat-new-password" name="repeat-new-password" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <br />
                            <div class="form-group">
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn btn-success me-3" onClick={changePassword}>ثبت</button>
                                    {/* <Link to="/teachers">
                                        <button type="submit" class="btn btn-primary">انصراف</button>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </Content>
                </Card>
            </div>
        </div>
    );
};

export default ProfilePage;



