import { useEffect, useRef, useState } from 'react';

import Popup from 'reactjs-popup';
import Card from '../../components/Layout/card/Card';
import SecondaryContentAddComponent from './SecondaryContentAddComponent';
import htmlContentService from '../../services/htmlContent.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const SecondaryContentComponent = (props) => {
    const navigate = useNavigate();
    const [lessonId, setLessonId] = useState(-1);
    const [bookId, setBookId] = useState(-1);
    const [htmlContents, setHtmlContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //modals controller

    const [clickedIdToRemove, setClickedIdToRemove] = useState(-1);
    const editRef = useRef();
    const [clickedIdToEdit, setClickedIdToEdit] = useState(-1);

    useEffect(() => {
        setBookId(props.bookId);
        setLessonId(props.lessonId);
        getSecondaryHtmlContentsOfLesson(props.lessonId);
    }, [clickedIdToEdit]);

    function getSecondaryHtmlContentsOfLesson(lessonId) {
        setIsLoading(true);
        setClickedIdToEdit(-1);
        htmlContentService.getSecondaryHtmlContentsOfLesson(lessonId).then(
            (response) => {
                setHtmlContents(response.data.htmlContents);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function confirmDelete(htmlContentId) {
        setClickedIdToRemove(htmlContentId);
    }

    function editHtmlContentHandler(htmlContentId) {
        setClickedIdToEdit(htmlContentId);
        editRef.current.click();
    }

    function goToLessonEditorPage(htmlContentId) {
        navigate('/lesson-editor', { state: { htmlContentId: htmlContentId, bookId: bookId } });
    }

    return (
        <div className='table-responsive m-3'>
            <Card>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    افزودن
                </button>
                {lessonId !== -1 && <SecondaryContentAddComponent lessonId={lessonId} getSecondaries={() => getSecondaryHtmlContentsOfLesson(lessonId)} />}
            </Card>

            <table class="table table-hover text-center">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>عنوان</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {htmlContents.map((htmlContent, i) => {
                        return <tr>
                            <td style={{ cursor: 'pointer', width: '100px' }} onClick={() => goToLessonEditorPage(htmlContent["id"])}>{i + 1}</td>
                            <td style={{ cursor: 'pointer' }} onClick={() => goToLessonEditorPage(htmlContent["id"])}>{htmlContent["title"]}</td>
                            <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                <div class="dropdown">
                                    <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={() => editHtmlContentHandler(htmlContent["id"])}><a class="dropdown-item">ویرایش</a></li>
                                        <li onClick={() => confirmDelete(htmlContent["id"])}>حذف</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    })}

                    {Object.keys(htmlContents).length === 0 && !isLoading &&
                        <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                    }

                    {isLoading &&
                        <tr><td className="text-center" colSpan={6}>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td></tr>
                    }
                </tbody>
            </table>

            {/* {lessonId !== -1 && clickedIdToEdit !== -1 && <SecondaryContentEditComponent lessonId={lessonId} htmlContentId={clickedIdToEdit} getSecondaries={() => getSecondaryHtmlContentsOfLesson(lessonId)} />}
            <li className='d-none' data-bs-toggle="modal" data-bs-target="#exampleModal1" ref={editRef}><a class="dropdown-item">ویرایش</a></li> */}


        </div>
    );
};

export default SecondaryContentComponent;



