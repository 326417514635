import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const getStudentPanel = (mobile) => {
  return api.get('/Manager/StudentPanel/Get');
};

const updateStudentPanel = (id, html, css,) => {
  var data = {
    "id": id,
    "html": html,
    "css": css,
    "AcademyID": -1,
  };

  return api.put('/Manager/StudentPanel/Update', data);
};

export default {
  getStudentPanel,
  updateStudentPanel,
};
