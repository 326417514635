import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import LessonsListForStudentComponent from './LessonsListForStudentComponent';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AllDontCheckAnswersOfCourseComponent from '../teacher/AllDontCheckAnswersOfCourseComponent';
import AllAnswersOfStudentInCourseComponent from '../teacher/AllAnswersOfStudentInCourseComponent';
import LessonsOfStudentInCourseComponent from './LessonsOfStudentInCourseComponent';


const StudentInfoComponent = (props) => {
    // const [pageId, setPageId] = useState(1);
    const [student, setStudent] = useState(-1);
    const [courseId, setCourseId] = useState(-1);

    useEffect(() => {
        setStudent(props.student)
        console.log(props.student)
        setCourseId(props.courseId)
    }, []);


    return (
        <div>
            {/* {props.studentId} */}
            <div className='border d-flex justify-content-around p-2 align-items-center'>
                <p>نام: {student["name"]}</p>
                <p>فامیل: {student["family"]}</p>
                <p>نام کاربری: {student["mobile"]}</p>
                {/* <p>{student["id"]}</p>
                <p>{courseId}</p> */}
            </div>
            <div>
                <Tabs className="border p-2 mt-4">
                    <TabList>
                        <Tab>جلسات</Tab>
                        <Tab>نمودار</Tab>
                        <Tab>تکالیف ارسال شده</Tab>
                    </TabList>

                    <TabPanel>
                        {courseId != -1 && courseId != false && <LessonsOfStudentInCourseComponent studentId={student.id} courseId={courseId} />}
                    </TabPanel>
                    <TabPanel>

                    </TabPanel>
                    <TabPanel>
                        {courseId != -1 && courseId != false && <AllAnswersOfStudentInCourseComponent studentId={student.id} courseId={courseId} />}
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

export default StudentInfoComponent;



