

const Title = ({ children }) => {
    return (
        <div class="x_title">
            {children}
        </div>
    );
};

export default Title;
