import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import academyStudentsService from "../../services/academyStudents.service";
import { useEffect, useState } from "react";

const StudentsComponent = (props) => {
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAcademyStudents("", "", "");
    }, []);

    function getAcademyStudents(studentName, studentFamily, studentUsername) {
        setIsLoading(true);
        academyStudentsService.allStudentsOfAcademy(studentName, studentFamily, studentUsername).then(
            (response) => {
                setStudents(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p></p>
                        <Link to="/add-student">
                            <button type="button" class="btn btn-primary">افزودن دانشجوی جدید</button>
                        </Link>
                    </div>
                </Title>
                <Content>
                    <div class="table-responsive">
                        <table class="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>نام</th>
                                    <th>نام خانوادگی</th>
                                    <th>نام کاربری</th>
                                    <th>برچسب ها</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {students.map((student, i) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        <td>{student["name"]}</td>
                                        <td>{student["family"]}</td>
                                        <td>{student["mobile"]}</td>
                                        <td></td>
                                        <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a class="dropdown-item" href="#">حذف</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                })}



                                {Object.keys(students).length === 0 && !isLoading &&
                                    <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                                }

                                {isLoading &&
                                    <tr><td className="text-center" colSpan={5}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td></tr>
                                }


                            </tbody>
                        </table>
                    </div>
                </Content>

            </Card>
        </div>
    );
};

export default StudentsComponent;



