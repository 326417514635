import "./style.css";

const Card = ({ children }) => {
    return (
        <div class="x_panel">
            {children}
        </div>
    );
};

export default Card;