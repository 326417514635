import Settings from "./Settings";
import AdminPanel from "./components/Layout/AdminPanel"
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

const BlankPrivateRoutes = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    return currentUser ? <Outlet /> : <Navigate to={localStorage.getItem("academyUniqueCode") + "/login"} />;
};

export default BlankPrivateRoutes;
