import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

// const destroy = (bookId) => {
//   return api.delete('/Teacher/Book/Delete/' + bookId);
// };

const allPagesOfAcademy = () => {
  return api.get('/Manager/WebPage/AllPagesOfAcademy');
};

const insertPage = (title, address, htmlText, css, keywords) => {
  var data = {
    "address": address,
    "title": title,
    "htmlText": htmlText,
    "css": css,
    "keywords": keywords,
  };

  return api.post('/Manager/WebPage/InsertPage', data);
};

const updatePage = (pageId, title, address, htmlText, css, keywords) => {
  var data = {
    "id": pageId,
    "address": address,
    "title": title,
    "htmlText": htmlText,
    "keywords": keywords,
    "css": css,
  };

  return api.put('/Manager/WebPage/UpdatePage', data);
};

const getPageById = (pageId) => {
  return api.get('/Manager/WebPage/GetPageById/' + pageId);
};

const destroy = (pageId) => {
  return api.delete('/Manager/WebPage/Delete/' + pageId);
};

export default {
  getPageById,
  destroy,
  insertPage,
  updatePage,
  allPagesOfAcademy,
};
