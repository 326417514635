import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

// const destroy = (bookId) => {
//   return api.delete('/Teacher/Book/Delete/' + bookId);
// };

const GetAllAnswersOfCourse = (courseId) => {
  return api.get('/Teacher/Answer/GetAllAnswersOfCourse/' + courseId);
};

const GetAllAnswersOfStudentInCourse = (studentId, courseId) => {
  return api.get('/Teacher/Answer/GetAllAnswersOfStudentInCourse/' + studentId + "/" + courseId);
};

const GetAllDontCheckAnswersOfCourse = (courseId) => {
  return api.get('/Teacher/Answer/GetDontCheckAnswersOfCourse/' + courseId);
};

const updateEarnedPoint = (csqId, point) => {
  return api.get('/Teacher/Answer/UpdateEarnedPoint/' + csqId + "/" + point);
};

export default {
  updateEarnedPoint,
  GetAllAnswersOfCourse,
  GetAllDontCheckAnswersOfCourse,
  GetAllAnswersOfStudentInCourse,
};
