import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const allBooksOfTeacher = () => {
  return api.get('/Teacher/Book/AllBooksOfTeacher');
};

const insertBookToTeacher = (bookName, bookDescription) => {
  var data = {
    "name": bookName,
    "description": bookDescription,
  };

  return api.post('/Teacher/Book/InsertBook', data);
};

const updateBook = (bookId, bookName, bookDescription) => {
  var data = {
    "name": bookName,
    "description": bookDescription,
  };

  return api.put('/Teacher/Book/UpdateBook/' + bookId, data);
};

export default {
  updateBook,
  allBooksOfTeacher,
  insertBookToTeacher,
};
