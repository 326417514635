import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import lessonService from '../../services/lesson.service';
import { toast } from 'react-toastify';
import LessonDeleteComponent from './LessonDeleteComponent';


const LessonSettingComponent = (props) => {
  const navigate = useNavigate();
  const [lessonId, setLessonId] = useState(-1);
  const [lessonName, setLessonName] = useState("");
  const [lessonTags, setLessonTags] = useState("");
  const [lessonOrder, setLessonOrder] = useState(0);

  useEffect(() => {
    setLessonId(props.lessonId);
    setLessonName(props.lessonName);
    setLessonTags(props.lessonTags);
    setLessonOrder(props.lessonOrder);
  }, []);

  function updateLesson() {
    lessonService.updateLesson(lessonId, lessonName, lessonTags, lessonOrder).then(
      (response) => {
        toast.info("ذخیره شد");
        props.getLessons(false);
        window.location.reload();
      },
      (error) => {

      }
    );
  }

  function lessonNameInputChange(e) {
    setLessonName(e.target.value);
  }

  function lessonTagsInputChange(e) {
    setLessonTags(e.target.value);
  }

  function lessonOrderInputChange(e) {
    setLessonOrder(e.target.value);
  }

  return (
    <div class="row" style={{ overflow: 'auto' }}>
      <div class="col-md-4" ></div>
      <div class="col-md-4" >
        <label class="control-label mt-4" for="course-name">نام جلسه *</label>
        <input value={lessonName} onChange={lessonNameInputChange} type="text" id="course-name" required="required" class="form-control" />

        <label class="control-label mt-4" for="course-tag">برچسب ها</label>
        <input value={lessonTags} onChange={lessonTagsInputChange} type="text" id="course-tag" required="required" class="form-control" />

        <label class="control-label mt-4" for="course-order">ترتیب</label>
        <input value={lessonOrder} onChange={lessonOrderInputChange} type="text" id="course-order" required="required" class="form-control" />

        <br />

        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
            <label class="form-check-label" for="flexSwitchCheckDefault">عدم نمایش به دانش آموز</label>
        </div>

        <br />
        <div class="text-center"><input type='button' onClick={() => updateLesson()} class='btn btn-primary w-100' value="ذخیره" /></div>
        <hr />
        {/* <p className='text-center'>برای حذف جلسه کلیک کنید.</p> */}
        {lessonId != -1 && <LessonDeleteComponent lessonId={lessonId} getLessons={props.getLessons} />}
      </div>
      <div class="col-md-4" ></div>
    </div>
  )
}



export default LessonSettingComponent;