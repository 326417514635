import React, { useEffect } from "react";
import LoginCore from "../../LoginCore";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";

const Login = (props) => {
  const navigate = useNavigate();
  const { academyUniqueCode } = useParams();

  useEffect(() => {
    if (academyUniqueCode !== 'null') {
      localStorage.setItem("academyUniqueCode", academyUniqueCode);
    }
    else if (localStorage.getItem("academyUniqueCode") != null) {
      navigate("/" + localStorage.getItem("academyUniqueCode") + "/login")
    }
  }, [])

  return (
    <div class="authentication-wrapper authentication-cover" style={{ backgroundColor: '#f3f4f4', height: '100vh', overflow: 'hidden' }}>
      <div class="authentication-inner row m-0">

        <div class="d-flex col-md-6 align-items-center authentication-bg p-sm-1 p-1">
          <div class="mx-auto w-75" style={{ padding: '40px 5px' }}>
            <div class="app-brand mb-4">
              <Link to={"/login"} className="mb-2">
                <span class="app-brand-logo demo">
                  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>آیکن</title>
                    <defs>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                        <stop stop-color="#5A8DEE" offset="0%"></stop>
                        <stop stop-color="#699AF9" offset="100%"></stop>
                      </linearGradient>
                      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-2">
                        <stop stop-color="#FDAC41" offset="0%"></stop>
                        <stop stop-color="#E38100" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Login---V2" transform="translate(-667.000000, -290.000000)">
                        <g id="Login" transform="translate(519.000000, 244.000000)">
                          <g id="Logo" transform="translate(148.000000, 42.000000)">
                            <g id="icon" transform="translate(0.000000, 4.000000)">
                              <path d="M13.8863636,4.72727273 C18.9447899,4.72727273 23.0454545,8.82793741 23.0454545,13.8863636 C23.0454545,18.9447899 18.9447899,23.0454545 13.8863636,23.0454545 C8.82793741,23.0454545 4.72727273,18.9447899 4.72727273,13.8863636 C4.72727273,13.5423509 4.74623858,13.2027679 4.78318172,12.8686032 L8.54810407,12.8689442 C8.48567157,13.19852 8.45300462,13.5386269 8.45300462,13.8863636 C8.45300462,16.887125 10.8856023,19.3197227 13.8863636,19.3197227 C16.887125,19.3197227 19.3197227,16.887125 19.3197227,13.8863636 C19.3197227,10.8856023 16.887125,8.45300462 13.8863636,8.45300462 C13.5386269,8.45300462 13.19852,8.48567157 12.8689442,8.54810407 L12.8686032,4.78318172 C13.2027679,4.74623858 13.5423509,4.72727273 13.8863636,4.72727273 Z" id="Combined-Shape" fill="#4880EA"></path>
                              <path d="M13.5909091,1.77272727 C20.4442608,1.77272727 26,7.19618701 26,13.8863636 C26,20.5765403 20.4442608,26 13.5909091,26 C6.73755742,26 1.18181818,20.5765403 1.18181818,13.8863636 C1.18181818,13.540626 1.19665566,13.1982714 1.22574292,12.8598734 L6.30410592,12.859962 C6.25499466,13.1951893 6.22958398,13.5378796 6.22958398,13.8863636 C6.22958398,17.8551125 9.52536149,21.0724191 13.5909091,21.0724191 C17.6564567,21.0724191 20.9522342,17.8551125 20.9522342,13.8863636 C20.9522342,9.91761479 17.6564567,6.70030817 13.5909091,6.70030817 C13.2336969,6.70030817 12.8824272,6.72514561 12.5388136,6.77314791 L12.5392575,1.81561642 C12.8859498,1.78721495 13.2366963,1.77272727 13.5909091,1.77272727 Z" id="Combined-Shape2" fill="url(#linearGradient-1)"></path>
                              <rect id="Rectangle" fill="url(#linearGradient-2)" x="0" y="0" width="7.68181818" height="7.68181818"></rect>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </Link>
              <span class="h3 mb-5 fw-bold ms-3 text-primary">سامانه آموزشی</span>

            </div>
            <h5 class="mb-4">به سامانه آموزشی خوش آمدید!</h5>
            {/* <p class="mb-4">لطفا وارد حساب خود شده و ماجراجویی را شروع کنید</p> */}
            <LoginCore academyUniqueCode={academyUniqueCode} />

            {/* <a href="academy-rgister" style={{display: "block", margin: "auto", width: "100px"}}>ثبت آموزشگاه</a> */}

          </div>

        </div>


        <div class="d-none d-lg-flex col-md-6 col-xl-none align-items-center">
          <div class="flex-row text-center mx-auto" style={{ marginTop: '30px' }}>
            <img src={require('./login-light.png')} alt="Auth Cover Bg color" width="520" class="img-fluid authentication-cover-img" data-app-light-img="pages/login-light.png" data-app-dark-img="pages/login-dark.png" />
            <div class="mx-auto">
              <h3>قدرتمندترین سامانه آموزشی</h3>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Login;
