import api from "./API";

const register = (username, email, password) => {
  return api.post("signup", {
    username,
    email,
    password,
  });
};

const login = (username, password, academyUniqueCode, userType) => {
  return api.post("/Authentication/LoginTeacher", { username, password, academyUniqueCode }).then((response) => {
    if (response.data.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }

    return response.data.data;
  });
};

const updateCurrentUser = (name, family) => {
  var data = {
      "name": name,
      "family": family,
  };

  return api.put('/Authentication/UpdateCurrentUser/', data);
};

const changePassword = (oldPassword, newPassword) => {
  var data = {
      "oldPassword": oldPassword,
      "newPassword": newPassword,
  };

  return api.post('/Authentication/ChangePassword/', data);
};

const getProfile = () => {
  return api.get('/Authentication/GetCurrentUser/');
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  changePassword,
  updateCurrentUser,
  getProfile,
  register,
  login,
  logout,
};
