export const AdminPanelSettings = {
    name: "سامانه آموزشی",
    sidebar_footer_visible: true,
    footer_visible: true,
    mini_profile: true,
    show_messages: false,
    body_classes: ['nav-md'],
    css_list:[
    // "vendors/bootstrap/dist/css/bootstrap.min.css",
    // "vendors/bootstrap-rtl/dist/css/bootstrap-rtl.min.css",
    // "vendors/custom.min.css",
    ],
    javascript_lists: [

    ],
};
