import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import academyTeachersService from "../../services/academyTeachers.service";

const AddTeacherPage = (props) => {
    const navigate = useNavigate();
    const [teacherName, setTeacherName] = useState("");
    const [teacherFamily, setTeacherFamily] = useState("");
    const [teacherMobile, setTeacherMobile] = useState("");
    const [teacherPassword, setTeacherPassword] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    function insertTeacherToAcademy() {
        setIsSaving(true);
        academyTeachersService.insertTeacherToAcademy(teacherName, teacherFamily, teacherMobile, teacherPassword).then(
            (response) => {
                navigate("/users");
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsSaving(false);
    }

    function teacherNameChange(e) {
        setTeacherName(e.target.value);
    }

    function teacherFamilyChange(e) {
        setTeacherFamily(e.target.value);
    }

    function teacherMobileChange(e) {
        setTeacherMobile(e.target.value);
    }

    function teacherPasswordChange(e) {
        setTeacherPassword(e.target.value);
    }

    return (
        <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <Card>
                    <Title>
                        <div>
                            <p>افزودن مربی جدید</p>
                        </div>
                    </Title>
                    <Content>

                        <div class="form-label-center ">
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">نام
                                    <span class="required">*</span>
                                </label>
                                <div class="">
                                    <input value={teacherName} onChange={teacherNameChange} type="text" id="first-name" required="required" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">نام
                                    خانوادگی <span class="required">*</span>
                                </label>
                                <div class="">
                                    <input value={teacherFamily} onChange={teacherFamilyChange} type="text" id="last-name" name="last-name" required="required" class="form-control col-md-7 col-xs-12" />
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="mobile">
                                    شماره موبایل <span class="required">*</span>
                                </label>
                                <div class="">
                                    <input value={teacherMobile} onChange={teacherMobileChange} type="text" id="mobile" name="mobile" required="required" class="form-control col-md-7 col-xs-12" />
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">رمز عبور<span class="required">*</span></label>
                                <div class="">
                                    <input value={teacherPassword} onChange={teacherPasswordChange} type="text" id="last-name" name="last-name" required="required" class="form-control col-md-7 col-xs-12" />
                                </div>
                            </div>
                            <div class="checkbox form-group">
                                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">
                                </label>
                                <div class="">
                                    <input type="checkbox" value="" checked />
                                    <lable className="ms-2">ارسال نام کاربری و رمز عبور به موبایل مربی</lable>
                                </div>
                            </div>
                            <br />
                            {/* <div class="ln_solid"></div> */}
                            <div class="form-group">
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn btn-success me-3" onClick={insertTeacherToAcademy}>ثبت</button>
                                    <Link to="/users">
                                        <button type="submit" class="btn btn-primary">انصراف</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Content>

                </Card>
            </div>
        </div>
    );
};

export default AddTeacherPage;



