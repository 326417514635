import { Editor } from "@tinymce/tinymce-react";

import Card from "../../components/Layout/card/Card";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import htmlContentService from "../../services/htmlContent.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import webPageService from "../../services/webpage.service";
import studentPanelService from "../../services/student.panel.service";
import { toast } from "react-toastify";


const StudentPanelPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const [bookId, setBookId] = useState(-1);
    // const [pageId, setPageId] = useState(-1);
    // const [pageTitle, setPageTitle] = useState("");
    // const [pageAddress, setPageAddress] = useState("");
    // const [pageKeywords, setPageKeywords] = useState("");
    const [id, setId] = useState("");
    const [html, setHtml] = useState("");
    const [css, setCss] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // if (props.pageId != -1) {
        //     setPageId(props.pageId);
        //     getPageById(props.pageId);
        // }
        getStudentPanel();
    }, []);

    function getStudentPanel() {
        setIsLoading(true);
        studentPanelService.getStudentPanel().then(
            (response) => {
                // navigate("/teachers");
                setId(response.data.id);
                setHtml(response.data.html);
                setCss(response.data.css);
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsLoading(false);
    }

    // function getPageById(pageId) {
    //     webPageService.getPageById(pageId).then(
    //         (response) => {
    //             setPageTitle(response.data.title);
    //             setPageAddress(response.data.address);
    //             setPageKeywords(response.data.keywords);
    //             setHtml(response.data.htmlText);
    //             setCss(response.data.css);
    //         },
    //         (error) => {
    //         }
    //     );
    // }

    // function insertPage() {
    //     setIsSaving(true);
    //     webPageService.insertPage(pageTitle, pageAddress, html, css, pageKeywords).then(
    //         (response) => {
    //             // props.updateHtmlContent(html, css);
    //             setIsSaving(false);
    //         },
    //         (error) => {
    //             setIsSaving(false);
    //         }
    //     );
    // }

    function update() {
        setIsSaving(true);
        studentPanelService.updateStudentPanel(id, html, css).then(
            (response) => {
                setHtml(response.data.html);
                setCss(response.data.css);
                setIsSaving(false);
                toast.info("آپدیت شد.")
            },
            (error) => {
                setIsSaving(false);
            }
        );
    }

    function goToPreviewPage() {
        // window.open('/lesson-preview/' + htmlContent.id, '_blank', 'noreferrer');
    }

    // function btnSaveHandler() {
    //     // alert(pageId);
    //     if (pageId == -1) {
    //         // alert("insert");
    //         insertPage();
    //     }

    //     else {
    //         // alert("update");
    //         updatePage();
    //     }
    // }

    // function goToLessonPage() {
    //     props.changeEditMode(false);
    // }

    function handleHtmlChange(e) {
        setHtml(e.target.value);
    }

    function handleCssChange(e) {
        setCss(e.target.value);
    }


    return (
        <div style={{ height: "100vh" }}>
            <div className="mb-3">
            </div>
            <Tabs>
                <TabList>
                    {/* {pageId != -1 && <Tab>پیشنمایش</Tab>} */}
                    <Tab>کد Html</Tab>
                    <Tab>کد css</Tab>
                    {/* <Tab>تنظیمات</Tab> */}

                    <a onClick={() => update()} type="button" className="btn btn-sm btn-primary ms-3">
                        <span className="text-white">ذخیره
                            {isSaving && <div style={{ width: '18px', height: '18px' }} class="spinner-border" role="status" />}
                        </span>
                    </a>

                </TabList>

                {/* {pageId != -1 && <TabPanel>
                    <div className="w-100" style={{ backgroundColor: '#FAF4F3' }}>
                        <style>{css}</style>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </TabPanel>} */}
                <TabPanel>
                    <textarea onChange={handleHtmlChange} value={html} rows="23" class="form-control" id="exampleFormControlTextarea1" dir="ltr"></textarea>
                </TabPanel>
                <TabPanel>
                    <textarea onChange={handleCssChange} value={css} rows="23" class="form-control" id="exampleFormControlTextarea1" dir="ltr"></textarea>
                </TabPanel>
                {/* <TabPanel>
                    <div className="row mt-5">
                        <div class="col-md-3" />
                        <div class="col-md-6 form-horizontal form-label-left">
                            <div class="form-group">
                                <label for="page-title"> عنوان صفحه * </label>
                                <input value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} type="text" id="page-title" class="form-control" />
                            </div>
                            <div class="form-group mt-4">
                                <label for="page-address"> آدرس صفحه * </label>
                                <input dir="ltr" value={pageAddress} onChange={(e) => setPageAddress(e.target.value)} type="text" id="page-address" class="form-control" />
                            </div>
                            <div class="form-group mt-4">
                                <label for="page-keywords"> کلمات کلیدی </label>
                                <input dir="ltr" value={pageKeywords} onChange={(e) => setPageKeywords(e.target.value)} type="text" id="page-keywords" class="form-control" />
                            </div>
                        </div>
                    </div>
                </TabPanel> */}
            </Tabs>
        </div>

    );
};

export default StudentPanelPage;



