import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import courseStudentsService from "../../services/courseStudents.service";
import htmlContentService from "../../services/htmlContent.service";


const LessonViewerForStudentPage = () => {
    const navigate = useNavigate();
    const { studentCourseCode } = useParams();
    const { lessonId } = useParams();
    const [htmlContent, setHtmlContent] = useState({});

    const [isValidRequest, setIsValidRequest] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // getLessonByLessonIdAndStudentCode(studentCourseCode, lessonId);
        getHtmlContentById(lessonId, studentCourseCode);
    }, []);

    // function getLessonByLessonIdAndStudentCode(studentCourseCode, lessonId) {
    //     setIsLoading(true);
    //     courseStudentsService.getLessonByLessonIdAndStudentCode(studentCourseCode, lessonId).then(
    //         (response) => {
    //             setLessonTitle(response.data["title"]);
    //             setHtmlContents(response.data["htmlContents"]);
    //             setHtml(response.data["htmlContents"][0]["htmlText"]);
    //             setCss(response.data["htmlContents"][0]["css"]);
    //             setTimeout(() => setIsLoading(false), 2000)
    //         },
    //         (error) => {
    //             setTimeout(setIsLoading(false), 2000)
    //         }
    //     );
    // }

    function getHtmlContentById(htmlContentId, studentCourseCode) {
        htmlContentService.getHtmlContentByIdForStudent(htmlContentId, studentCourseCode).then(
            (response) => {
                setHtmlContent(response.data.htmlContent);
                setTimeout(() => setIsLoading(false), 1000);
            },
            (error) => {
                setTimeout(() => { setIsLoading(false); setIsValidRequest(false) }, 1000);
            }
        );
    }

    function backUri() {
        navigate("/course/" + studentCourseCode);
    }

    return (

        <div>
            {isValidRequest &&
                <div>
                    <nav class="navbar fixed-top" style={{ backgroundColor: "#8E95F1" }}>
                        <div class="container">
                            <div class="btn-group">
                                <div className="d-flex align-items-center">
                                    {/* <iconify-icon icon="entypo:dots-three-vertical" width="25" height="18" style={{ color: "white", cursor: "pointer" }} data-bs-toggle="dropdown" data-bs-auto-close="true"></iconify-icon> */}
                                    {/* <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                {htmlContents.map((htmlContent, i) => {
                                    return <li><a class="dropdown-item" href="#">{htmlContent["title"]}</a></li>
                                })}
                            </ul> */}
                                </div>
                                <a class="navbar-brand ms-2" href="#" style={{ color: 'white' }}>{htmlContent.title}</a>
                            </div>
                            <span style={{ cursor: "pointer" }} onClick={() => backUri()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="white" d="M11.707 5.293a1 1 0 0 1 0 1.414L7.414 11H19a1 1 0 1 1 0 2H7.414l4.293 4.293a1 1 0 0 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0"/></svg>                            </span>

                        </div>
                    </nav>
                    {!isLoading &&
                        <div className="w-100" style={{ padding: '10px' }}>
                            <style>{htmlContent.css}</style>
                            <div style={{ marginTop: '60px' }} dangerouslySetInnerHTML={{ __html: htmlContent.htmlText }} />
                        </div>
                    }

                    {!isLoading &&
                        <div className="position-fixed border rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', backgroundColor: 'blue', bottom: '15px', left: '15px', cursor: 'pointer' }}>
                            <iconify-icon icon="material-symbols-light:help-outline" width="32" height="32" style={{ color: "white" }}></iconify-icon>
                        </div>
                    }
                    {isLoading &&
                        // <div class="spinner-border text-primary position-absolute top-50 start-50" role="status">
                        //     <span class="visually-hidden">Loading...</span>
                        // </div>
                        <div class="spinner-grow text-primary position-absolute top-50 start-50" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
            }
            {!isValidRequest &&
                <div className="hd-flex justify-content-center">
                    <h6 className="position-absolute top-50 w-100 text-center">درخواست نامعتبر</h6>
                </div>
            }
        </div>

    );
};

export default LessonViewerForStudentPage;



