import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const destroy = (bookId) => {
  return api.delete('/Teacher/Book/Delete/' + bookId);
};

const getBookWithLessons = (bookId) => {
  return api.get('/Teacher/Book/GetBookWithLessonsAndTechers/' + bookId);
};

const addTeacherToBook = (bookId, mobile) => {
  return api.get('/Teacher/Book/AddBookToTeacher/' + bookId + "/" + mobile);
};

const removeBookFromTeacher = (bookId, mobile) => {
  return api.get('/Teacher/Book/RemoveBookFromTeacher/' + bookId + "/" + mobile);
};

export default {
  destroy,
  addTeacherToBook,
  getBookWithLessons,
  removeBookFromTeacher,
};
