import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const uploadImage = (formData) => {
  return api.post('/Teacher/Image/Upload', formData);
};

const getTeacherImages = (formData) => {
  return api.get('/Teacher/Image/GetTeacherImages', formData);
};

const destroy = (imageName) => {
  return api.delete('/Teacher/Image/Delete/' + imageName);
};

export default {
  destroy,
  uploadImage,
  getTeacherImages,
};
