import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import LessonsListForStudentComponent from './LessonsListForStudentComponent';
import { Badge, BottomNavigation, BottomNavigationAction, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import RestoreIcon from '@mui/icons-material/Restore';
import HomeIcon from '@mui/icons-material/Home';
import courseStudentsService from '../../services/courseStudents.service';
import SettingsIcon from '@mui/icons-material/Settings';
import QuizIcon from '@mui/icons-material/Quiz';
import AzmoonComponent from './AzmoonComponent';
import Avatar from '@mui/material/Avatar';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { styled } from '@mui/material/styles';

const StudentHomePage = () => {
    const [pageId, setPageId] = useState(1);
    const [student, setStudent] = useState([]);
    const [course, setCourse] = useState([]);
    const { studentCourseCode } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [lessons, setLessons] = useState([]);

    const [bottomNavigationValue, setBottomNavigationValue] = useState(false);
    let navigate = useNavigate();

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    useEffect(() => {
        getDetailsOfStudentCode(studentCourseCode);
    }, []);

    function getDetailsOfStudentCode(studentCourseCode) {
        setIsLoading(true);
        courseStudentsService.getDetailsOfStudentCode(studentCourseCode).then(
            (response) => {
                setStudent(response.data["student"]);
                setCourse(response.data["course"]);
                setLessons(response.data["lessons"]);
                setIsLoading(false);
            },
            (error) => {
                alert("کد وارد شده اشتباه است");
                setIsLoading(false);
            }
        );
    }

    function changePage(pageNum) {
        setPageId(pageNum);
    }

    return (
        <div>
            <div className="d-flex justify-content-between border align-items-center p-1">
                <p className="m-0">{course.name}</p>
                <div className='d-flex align-items-center'>
                    <p className="me-3 mb-0">{student.name} {student.family}</p>
                    <StyledBadge
                        className='me-3'
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    </StyledBadge>
                </div>
            </div>
            {pageId == 1 && <LessonsListForStudentComponent key={isLoading} lessons={lessons} />}
            {pageId == 2 && <AzmoonComponent />}
            <BottomNavigation className='border' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { sm: `none` } }} showLabels value={bottomNavigationValue} onChange={(event, newValue) => { setBottomNavigationValue(newValue); }}>
                <BottomNavigationAction onClick={() => changePage(1)} label={<span style={{ fontFamily: "Vazir" }}>جلسات</span>} icon={<HomeIcon />} />
                <BottomNavigationAction onClick={() => changePage(2)} label={<span style={{ fontFamily: "Vazir" }}>آزمون ها</span>} icon={

                    <Badge badgeContent={1} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                        <QuizIcon />
                    </Badge>

                } />
                <BottomNavigationAction onClick={() => changePage(2)} label={<span style={{ fontFamily: "Vazir" }}>چت</span>} icon={

                    <Badge badgeContent={5} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                        <ChatBubbleOutlineIcon />
                    </Badge>

                } />
                <BottomNavigationAction onClick={() => changePage(3)} label={<span style={{ fontFamily: "Vazir" }}>تنظیمات</span>} icon={<SettingsIcon />} />
            </BottomNavigation>
        </div >
    );
};

export default StudentHomePage;



