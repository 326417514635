import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WebsiteAddComponent from "./WebsiteAddComponent";
import WebsiteListComponent from "./WebsiteListComponent";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import MediaGalleryComponent from "../teacher/MediaGalleryComponent";
import imageService from "../../services/image.service";
import webSiteService from "../../services/website.service";
import ImageSelector from "../../components/ImageSelector/ImageSelector";

const WebsiteSettingPage = (props) => {
    const [webSite, setWebSite] = useState("");
    const [logoFileName, setLogoFileName] = useState("");

    useEffect(() => {
        getWebsite();
    }, []);

    function logoFileNameChange(logoFileName) {
        setLogoFileName(logoFileName);
    }

    function getWebsite() {
        webSiteService.getWebSite().then(
            (response) => {
                setWebSite(response.data);
                setLogoFileName(response.data.favIconUri);
            },
            (error) => {

            }
        );
    }

    function updateWebSite() {
        webSiteService.update(webSite.id, logoFileName).then(
            (response) => {
                toast.info("بروز رسانی شد.");
            },
            (error) => {

            }
        );
    }

    return (
        <div className="w-100">
            <div className="row mt-5">
                <div class="col-md-3" />
                <div class="col-md-6 border">
                    آیکن سایت *
                    <ImageSelector key={logoFileName} onSelect={logoFileNameChange} logoFileName={logoFileName} />
                    <button onClick={updateWebSite} className="btn btn-primary m-auto d-block mb-3">ذخیره</button>
                </div>
            </div>
        </div>
    );
};

export default WebsiteSettingPage;



