import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const sendSms = (mobile) => {
  return api.get('/Student/register/sendOtpsms?mobile=' + mobile);
};

const verifyOtpCodeAndRegister = (otpCode, studentMobile, studentName, studentFamily, courseRegisterationCode) => {
  var data = {
    "name": studentName,
    "family": studentFamily,
    "phoneNumber": studentMobile,
  };
  return api.post('Student/register/verifyOtpCode/' + otpCode + "/" + courseRegisterationCode, data);
};


export default {
  sendSms,
  verifyOtpCodeAndRegister,
  // getTeacherImages,
};
