import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import academyRegisterationService from "../../services/academy-registeration.service";
import { useNavigate } from "react-router-dom";

const AcademyRegisterPage = (props) => {
    const navigate = useNavigate();
    const [academyName, setAcademyName] = useState("");
    const [managerName, setManagerName] = useState("");
    const [managerFamily, setManagerFamily] = useState("");
    const [managerMobile, setManagerMobile] = useState("");
    const [managerPassword, setManagerPassword] = useState("");
    const [managerConfirmPassword, setManagerConfirmPassword] = useState("");

    function registerAcademyWithManager() {

        if (managerPassword != managerConfirmPassword) {
            toast.error("رمز عبور و تکرار رمز عبور مطابقت ندارد");
            return;
        }

        academyRegisterationService.academyRegisteration(academyName, managerName, managerFamily, managerMobile, managerPassword).then(
            (response) => {
                navigate('/');
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    function inputPasswordChange(e) {
        setManagerPassword(e.target.value);
    }

    function inputConfirmPasswordChange(e) {
        setManagerConfirmPassword(e.target.value);
    }

    function academyNameHandler(e) {
        setAcademyName(e.target.value);
    }

    function managerNameHandler(e) {
        setManagerName(e.target.value);
    }

    function managerFamilyHandler(e) {
        setManagerFamily(e.target.value);
    }

    function managerMobileHandler(e) {
        setManagerMobile(e.target.value);
    }

    return (
        <div className="w-100" style={{ display: 'flex', justifyContent: 'center', height: '100vh', backgroundColor: 'white' }}>
            <div className="form-horizontal" style={{ width: '600px', backgroundColor: '#4444', padding: '40px 10px 40px 20px', margin: '50px', borderRadius: '10px' }}>

                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">نام آموزشگاه
                        <span class="required"> *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={academyName} onChange={academyNameHandler} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">نام مدیر
                        <span class="required">  *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={managerName} onChange={managerNameHandler} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">فامیل مدیر
                        <span class="required">  *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={managerFamily} onChange={managerFamilyHandler} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">شماره موبایل
                        <span class="required">  *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={managerMobile} onChange={managerMobileHandler} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">رمز ورود
                        <span class="required">  *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={managerPassword} onChange={inputPasswordChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">تکرار رمز ورود
                        <span class="required">  *</span>
                    </label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input value={managerConfirmPassword} onChange={inputConfirmPasswordChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-success" onClick={registerAcademyWithManager}>ثبت</button>
                        <button onClick={()=>navigate('/')} type="submit" class="btn btn-primary">انصراف</button>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" rtl />
        </div>
    );
};

export default AcademyRegisterPage;



