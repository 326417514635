import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import courseService from "../../services/course.service";
import academyTeachersService from "../../services/academyTeachers.service";
import imageService from "../../services/image.service";
// import ImageSelector from "../../components/ImageSelector/ImageSelector";
import ImageSelector from "../../components/ImageSelector/ImageSelector";


import DatePicker, { DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";

const AddCourseComponent = (props) => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const [logoGalleryIsOpen, setLogoGalleryIsOpen] = useState(false);
    const [courseId, setCourseId] = useState(-1);
    const [selectedTeacherID, setSelectedTeacherID] = useState(-2); //dont change -2
    const [selectedStatusID, setSelectedStatusID] = useState(1);
    const [courseName, setCourseName] = useState("");
    const [courseShowPrice, setCourseShowPrice] = useState("");
    const [courseStartDate, setCourseStartDate] = useState("");
    const [courseSubText, setCourseSubText] = useState("");
    const [logoFileName, setLogoFileName] = useState("");
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        getAcademyTeachers();

        // if in update mode
        if (props.courseId != -1) {
            setCourseId(props.courseId);
            setSelectedStatusID(props.statusId);
            setCourseName(props.courseName);


            setLogoFileName(props.logoFileName);
            setCourseSubText(props.courseSubText);
            setCourseShowPrice(props.showPrice);

            if (props.startDate == "")
                setCourseStartDate("");
            else
                setCourseStartDate(new DateObject(props.startDate).convert(persian, persian_fa));
        }

    }, []);

    function btnSaveClicked() {
        if (courseId === -1) {
            // alert(courseId);
            insertCourse();
        }
        else {
            updateCourse();
        }
    }

    function insertCourse() {
        let englishStartDate = "";
        if (courseStartDate == "" || courseStartDate == null) {
            englishStartDate = "";
        }
        else {
            englishStartDate = new DateObject(courseStartDate).convert(gregorian, gregorian_en).format("DD-MM-YYYY");

        }

        courseService.insertCourse(courseName, selectedTeacherID, selectedStatusID, englishStartDate, logoFileName, courseShowPrice, courseSubText).then(
            (response) => {
                navigate("/academy-courses");
            },
            (error) => {

            }
        );
    }

    function updateCourse() {
        let englishStartDate = "";
        if (courseStartDate == "" || courseStartDate == null) {
            englishStartDate = "";
        }
        else {
            englishStartDate = new DateObject(courseStartDate).convert(gregorian, gregorian_en).format("DD-MM-YYYY");
        }

        courseService.updateCourse(courseId, courseName, selectedTeacherID, selectedStatusID, logoFileName, englishStartDate, courseShowPrice, courseSubText).then(
            (response) => {
                toast.success(response.data.message);
            },
            (error) => {

            }
        );
    }

    function getAcademyTeachers() {
        academyTeachersService.allTeachersOfAcademy().then(
            (response) => {
                setTeachers(response.data);

                // انتخاب معلم درس به عنوان معلم اول در اینپوت
                // if (selectedTeacherID == -2) {


                // if in update mode
                if (props.courseId != -1) {
                    try {
                        setSelectedTeacherID(props.courseTeachers[0]["id"]);

                    } catch (e) {
                        toast.info("مدرس را انتخاب کنید.")
                        setSelectedTeacherID(-1);
                    }
                }
                else { //if in insert mode
                    setSelectedTeacherID(response.data[0].id);
                }
            },
            (error) => {

            }
        );
    }

    // function logoNameSelected(logoFileName) {
    //     setLogoGalleryIsOpen(false);
    //     setLogoFileName(logoFileName);
    // }

    // function onSelectImageHandler(files) {
    //     const file = files[0];
    //     const formData = new FormData();
    //     formData.append('file', file)
    //     uploadCourseLogo(formData);
    // }

    // function uploadCourseLogo(formData) {
    //     imageService.uploadImage(formData).then(
    //         (response) => {

    //         },
    //         (error) => {

    //         }
    //     );
    // }

    function courseNameInputChange(e) {
        setCourseName(e.target.value);
    }

    function teacherInputChange(e) {
        setSelectedTeacherID(e.target.value);
    }

    function statusInputChange(e) {
        setSelectedStatusID(e.target.value);
    }

    function setLogoFileNameHandler(logoFileName1) {
        setLogoFileName(logoFileName1);
        // alert(logoFileName1);
    }

    return (
        <div class="form-horizontal form-label-left">
            <div class="form-group" >
                <br />
                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="teacher-name">عکس دوره *</label>
                <div style={{ textAlign: 'center' }} for="file">
                    <ImageSelector key={logoFileName} onSelect={(image) => setLogoFileNameHandler(image)} logoFileName={logoFileName} />
                </div>
                <label class="control-label" for="course-name">نام دوره *</label>
                <input value={courseName} onChange={courseNameInputChange} type="text" id="course-name" required="required" class="form-control" />
                <br />
                <label class="control-label" for="teacher-name">مربی دوره * </label>
                <select onChange={teacherInputChange} value={selectedTeacherID} class="form-select" aria-label="Default select example" id="teacher-name">
                    {teachers.map((teacher, i) => {
                        return <option value={teacher["id"]}>{teacher["name"]} {teacher["family"]}</option>
                    })}
                </select>
                <br />
                <label class="control-label" for="teacher-name">وضعیت دوره * </label>
                <select onChange={statusInputChange} value={selectedStatusID} class="form-select" aria-label="Default select example" id="course-status">
                    <option value="1">در حال ثبت نام</option>
                    <option value="2">در حال برگزاری</option>
                    <option value="3">اتمام دوره</option>
                </select>
                <br />
                <label class="control-label" for="course-name">هزینه</label>
                <input value={courseShowPrice} onChange={(e) => setCourseShowPrice(e.target.value)} type="text" id="course-name" required="required" class="form-control" />
                <br />
                <label class="control-label" for="teacher-name">شروع دوره</label>
                <DatePicker
                    value={courseStartDate}
                    onChange={value => setCourseStartDate(value)}
                    calendar={persian}
                    locale={persian_fa}
                    containerStyle={{
                        width: "100%"
                    }}
                    inputClass="form-control"
                />
                <br />
                <br />
                <label class="control-label" for="course-name">زیر عنوان</label>
                <textarea value={courseSubText} onChange={(e) => setCourseSubText(e.target.value)} className="form-control" name="Text1" cols="40" rows="5"></textarea>
                <br />
                <div className="text-center">
                    <button type="submit" class="btn btn-success mt-3" onClick={btnSaveClicked}>ثبت</button>
                </div>
            </div>
            <br />

        </div>
    );
};

export default AddCourseComponent;



