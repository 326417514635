import api from "./API";

const allAzmoonsOfLesson = (lessonId) => {
  return api.get('/Teacher/Azmoon/AllAzmoonsOfLesson/' + lessonId);
};

const getAzmoonById = (azmoonId) => {
  return api.get('/Teacher/Azmoon/GetAzmoonWithQuestionsById/' + azmoonId);
};

const insertAzmoon = (title, css, maxAllowedNum, periodTime, time, lessonId) => {
  var data = {
    "title": title,
    "css": css,
    "periodTime": periodTime,
    "maxAllowedNum": maxAllowedNum,
    "time": time,
    "lessonId": lessonId,
  };

  return api.post('/Teacher/Azmoon/InsertAzmoon', data);
};

const updateAzmoon = (id, title, css, maxAllowedNum, periodTime, time, lessonId) => {
  var data = {
    "id": id,
    "title": title,
    "css": css,
    "periodTime": periodTime,
    "maxAllowedNum": maxAllowedNum,
    "time": time,
    "lessonId": lessonId,
  };

  return api.put('/Teacher/Azmoon/UpdateAzmoon', data);
};

// const destroy = (bookId) => {
//   return api.delete('/Teacher/Book/Delete/' + bookId);
// };

export default {
  updateAzmoon,
  getAzmoonById,
  insertAzmoon,
  allAzmoonsOfLesson,
};
