import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";


const allStudentsOfAcademy = (studentName, studentFamily, studentUsername) => {
  var data = {
    "name": studentName,
    "family": studentFamily,
    "phoneNumber": studentUsername,
  };

  return api.post('/Manager/Student/AllStudents/', data);
};

const getLatestRegisteringStudents = (pageNumber) => {
  return api.get('/Manager/Academy/AllStudentsOfCourses/' + pageNumber);
};

const insertStudentToAcademy = (studentName, studentFamily, studentUsername) => {
  var data = {
    "name": studentName,
    "family": studentFamily,
    "phoneNumber": studentUsername,
  };

  return api.post('/Manager/Student/InsertStudent', data);
};

export default {
  allStudentsOfAcademy,
  insertStudentToAcademy,
  getLatestRegisteringStudents,
};
