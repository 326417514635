import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import academyStudentsService from "../../services/academyStudents.service";

const AddStudentPage = (props) => {
    const navigate = useNavigate();
    const [studentName, setStudentName] = useState("");
    const [studentFamily, setStudentFamily] = useState("");
    const [studentMobile, setStudentMobile] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    function insertStudentToAcademy() {
        setIsSaving(true);
        academyStudentsService.insertStudentToAcademy(studentName, studentFamily, studentMobile).then(
            (response) => {
                navigate("/users");
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsSaving(false);
    }

    function studentNameChange(e) {
        setStudentName(e.target.value);
    }

    function studentFamilyChange(e) {
        setStudentFamily(e.target.value);
    }

    function studentMobileChange(e) {
        setStudentMobile(e.target.value);
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div>
                        <p>افزودن دانشجوی جدید</p>
                    </div>
                </Title>
                <Content>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div class="form-group mt-2">
                                <label class="control-label" for="first-name">نام *</label>
                                <input value={studentName} onChange={studentNameChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <div class="form-group mt-3">
                                <label class="control-label" for="first-name">نام خانوادگی *</label>
                                <input value={studentFamily} onChange={studentFamilyChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            <div class="form-group mt-3">
                                <label class="control-label" for="first-name">شماره موبایل *</label>
                                <input value={studentMobile} onChange={studentMobileChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                            </div>
                            
                            {/* <input class='mt-4' type="checkbox" value="" disabled checked />ارسال نام کاربری و رمز عبور به موبایل دانشجو */}
                            <br />
                            <div class="form-group mt-3">
                                <div class="d-flex justify-content-around">
                                    <button type="submit" class="btn btn-success" onClick={insertStudentToAcademy}>ثبت</button>
                                    <Link to="/users">
                                        <button type="submit" class="btn btn-primary">انصراف</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </Content>

            </Card>
        </div>
    );
};

export default AddStudentPage;



