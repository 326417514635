import { useEffect, useState } from 'react';
import imageService from "../../services/image.service";
import Popup from 'reactjs-popup';
import ImageInfoComponent from './ImageInfoComponent';


const MediaGalleryComponent = (props) => {
    const [images, setImages] = useState([]);
    const [selectedThumbImageUri, setSelectedThumbImageUri] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isImageInfoViewer, setIsImageInfoViewer] = useState(false);

    useEffect(() => {
        getImages();
    }, []);

    function getImages() {
        setIsImageInfoViewer(false);
        imageService.getTeacherImages().then(
            (response) => {
                setImages(response.data);
            },
            (error) => {

            }
        );
    }

    function onUploadImageHandler(files) {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        uploadImage(formData);
    }

    function uploadImage(formData) {
        setIsUploading(true);
        imageService.uploadImage(formData).then(
            (response) => {
                getImages();
                setIsUploading(false);
            },
            (error) => {
                setIsUploading(false);
            }
        );
    }

    function selectedImage(thumbImageUri) {
        var mainImageUri = thumbImageUri.replace('/thumb/', '/');

        if (props.logoNameSelected == undefined) {
            setSelectedThumbImageUri(thumbImageUri);
            setIsImageInfoViewer(true);
        }
        else {
            props.logoNameSelected(mainImageUri);
        }
    }

    return (
        <div className='m-3'>
            {/* <div className='w-100 position-absolute end-0 bottom-50 bg-info'>
                ddd
            </div> */}

            <Popup open={isImageInfoViewer} on='click' onClose={() => setIsImageInfoViewer(false)} modal>
                <ImageInfoComponent thumbImageUri={selectedThumbImageUri} getImages={getImages} />
            </Popup>

            <div className='d-flex'>
                <h6 className='my-3'>تصاویر</h6>
                <label for="file" class="btn btn-primary ms-4" style={{ height: '40px' }}>
                    {!isUploading && <span>افزودن</span>}
                    {isUploading &&
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    }
                </label>
                <input type="file" id="file" style={{ visibility: "hidden" }} name="filename" onChange={(e) => onUploadImageHandler(e.target.files)} />
            </div>
            {/* <hr /> */}
            <div className='pt-3 overflow-auto' style={{ maxHeight: '80vh' }}>
                {images.map((image, index) => {
                    if (index % 6 === 0)
                        return (
                            <div className="row mb-2">
                                {images[index] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index]} onClick={() => selectedImage(images[index])} />}
                                {images[index + 1] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index + 1]} onClick={() => selectedImage(images[index + 1])} />}
                                {images[index + 2] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index + 2]} onClick={() => selectedImage(images[index + 2])} />}
                                {images[index + 3] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index + 3]} onClick={() => selectedImage(images[index + 3])} />}
                                {images[index + 4] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index + 4]} onClick={() => selectedImage(images[index + 4])} />}
                                {images[index + 5] != undefined && <img className='border col-md-2 p-3' src={process.env.REACT_APP_Files_URL + "/" + images[index + 5]} onClick={() => selectedImage(images[index + 5])} />}
                            </div>
                        );
                })}
            </div>
        </div>
    );
};

export default MediaGalleryComponent;



