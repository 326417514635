import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { createHashHistory } from 'history'
import { useCallback, useEffect, useRef, useState } from "react";
import courseService from "../../services/course.service";

import DatePicker, { DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

const AcademyCoursesPage = () => {
    const navigate = useNavigate();
    const deleteRef = useRef();
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [courseToDelete, setCourseToDelete] = useState(-1);

    useEffect(() => {
        getAcademyCourses();
    }, []);

    function getAcademyCourses() {
        setIsLoading(true);
        courseService.allCourseOfAcademy().then(
            (response) => {
                setCourses(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );

    }

    function deleteCourse() {
        courseService.deleteCourse(courseToDelete).then(
            (response) => {
                setCourseToDelete(-1);
                getAcademyCourses();
            },
            (error) => {

            }
        );
    }

    function btnDeleteHandler(courseId) {
        setCourseToDelete(courseId);
        deleteRef.current.click();
    }

    function goToCoursePage(courseId) {
        navigate('/add-course', { state: { courseId: courseId } });
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>کلاس های آموزشگاه</p>
                        <button type="button" class="btn btn-primary" onClick={() => goToCoursePage(-1)}>افزودن کلاس جدید</button>
                    </div>
                </Title>
                <Content>
                    <div class="table-responsive">
                        <table class="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>نام دوره</th>
                                    <th>وضعیت دوره</th>
                                    <th>تاریخ شروع</th>
                                    <th>مربی دوره</th>
                                    <th class='text-center'>تعداد هنرآموزان</th>
                                    <th>امکانات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.map((course, i) => {
                                    return <tr style={course["statusCode"] == 3 ? { backgroundColor: '#cfe3d4', cursor: 'pointer' } : { cursor: 'pointer' }}  >
                                        <td onClick={() => goToCoursePage(course["id"])}>{i + 1}</td>
                                        <td onClick={() => goToCoursePage(course["id"])}>
                                            <p >{course["name"]}</p>
                                        </td>
                                        <td onClick={() => goToCoursePage(course["id"])}>{course["status"]}</td>
                                        <td onClick={() => goToCoursePage(course["id"])}>{course["startDate"] == "" || course["startDate"] == null ? "" : new DateObject(course["startDate"]).convert(persian, persian_fa).toString()}</td>
                                        <td onClick={() => goToCoursePage(course["id"])}>{course["teacher"] != null && course["teacher"]["name"]} {course["teacher"] != null && course["teacher"]["family"]}</td>
                                        <td onClick={() => goToCoursePage(course["id"])} class='text-center'>{course["studentsCount"]}</td>
                                        <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li className="list-group-item list-group-item-action" onClick={() => btnDeleteHandler(course["id"])}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                        <span className="ms-2">حذف</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                })}

                                {Object.keys(courses).length === 0 && !isLoading &&
                                    <tr><td className="text-center" colSpan={10}>رکوردی یافت نشد</td></tr>
                                }

                                {isLoading &&
                                    <tr><td className="text-center" colSpan={7}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </Content>

            </Card>
            <button ref={deleteRef} type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                مدال حذف
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">تایید حذف</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            آیا از حذف مطمئن هستید؟
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">خیر</button>
                            <button type="button" class="btn btn-secondary" onClick={() => deleteCourse()} data-bs-dismiss="modal">بله</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcademyCoursesPage;



