import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";


const allTeachersOfAcademy = () => {
  return api.get('/AcademyTeacher/AllTeachersOfAcademy');
};

const insertTeacherToAcademy = (teacherName, teacherFamily, teacherMobile, teacherPassword) => {
  var data = {
      "name": teacherName,
      "family": teacherFamily,
      "phoneNumber": teacherMobile,
      "password": teacherPassword,
  };

  return api.post('/AcademyTeacher/RegisterTeacher', data);
};

export default {
  allTeachersOfAcademy,
  insertTeacherToAcademy,
};
