import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import RestoreIcon from '@mui/icons-material/Restore';
import HomeIcon from '@mui/icons-material/Home';

const AdminBottomNavigation = (props) => {

    const [bottomNavigationValue, setBottomNavigationValue] = useState(false);
    let navigate = useNavigate();

    function changeUri(uri){
        navigate(uri);
    }

    return (
        <BottomNavigation className='border' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { sm: `none` } }} showLabels value={bottomNavigationValue} onChange={(event, newValue) => { setBottomNavigationValue(newValue); }}>
            <BottomNavigationAction onClick={() => changeUri("/")} label={<span style={{ fontFamily: "Vazir" }}>صفحه نخست</span>} icon={<HomeIcon />} />
            <BottomNavigationAction onClick={() => changeUri("/books")} label={<span style={{ fontFamily: "Vazir" }}>درس های من</span>} value="/profile" to="/profile" icon={<RestoreIcon />} />
            <BottomNavigationAction onClick={() => changeUri("/my-courses")} label={<span style={{ fontFamily: "Vazir" }}>کلاس های من</span>} icon={<RestoreIcon />} />
        </BottomNavigation>
    );
}

export default AdminBottomNavigation;
