import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import webPageService from "../../services/webpage.service";
import { useEffect, useState } from "react";

const WebsiteListComponent = (props) => {
    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        allPagesOfAcademy();
    }, []);

    function allPagesOfAcademy() {
        setIsLoading(true);
        webPageService.allPagesOfAcademy().then(
            (response) => {
                setPages(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function goToUpdatePage(pageId) {
        props.goToUpdatePage(pageId);
    }

    function btnDeleteHandler(pageId) {
        setIsLoading(true);
        webPageService.destroy(pageId).then(
            (response) => {
                setPages(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    return (
        <div class="table-responsive">
            <table class="table table-hover text-center">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>عنوان صفحه</th>
                        <th>آدرس صفحه</th>
                        <th>تعداد بازدید</th>
                    </tr>
                </thead>
                <tbody>
                    {pages.map((page, i) => {
                        return <tr style={{ cursor: 'pointer' }}  >
                            <td onClick={() => goToUpdatePage(page["id"])}>{i + 1}</td>
                            <td onClick={() => goToUpdatePage(page["id"])}><p >{page["title"]}</p></td>
                            <td onClick={() => goToUpdatePage(page["id"])} dir="ltr">{page["address"]}</td>
                            <td onClick={() => goToUpdatePage(page["id"])}>{page["visitCount"]}</td>
                            <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                <div class="dropdown">
                                    <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li className="list-group-item list-group-item-action" onClick={() => btnDeleteHandler(page["id"])}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                            </svg>
                                            <span className="ms-2">حذف</span>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    })}

                    {Object.keys(pages).length === 0 && !isLoading &&
                        <tr><td className="text-center" colSpan={10}>رکوردی یافت نشد</td></tr>
                    }

                    {isLoading &&
                        <tr><td className="text-center" colSpan={7}>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td></tr>
                    }

                </tbody>
            </table>
        </div>

    );
};

export default WebsiteListComponent;



