import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const academyRegisteration = (academyName, managerName, managerFamily, managerMobile, managerPassword) => {
  var data = {
    "academy": {
      "name": academyName
    },
    "manager": {
      "name": managerName,
      "family": managerFamily,
      "phoneNumber": managerMobile,
      "password": managerPassword
    }
  };

  return api.post('/AcademyRegistration/Register', data, { headers: authHeader() });
};

export default {
  academyRegisteration,
};
