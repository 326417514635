import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import courseStudentsService from "../../services/courseStudents.service";
import courseService from "../../services/course.service";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";

const ShowCourseByCourseCode = () => {
    const navigate = useNavigate();
    const { courseRegisterationCode } = useParams();
    const [courseInfo, setCourseInfo] = useState({});
    const [logoFileName, setLogoFileName] = useState("");
    const [courseStartDate, setCourseStartDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        getCourseByRegisterationCode(courseRegisterationCode);
    }, []);

    function getCourseByRegisterationCode(registerationCode) {
        setIsLoading(true);
        courseService.getCourseByRegisterationCode(registerationCode).then(
            (response) => {
                setLogoFileName(response.data["logo"]);
                setCourseInfo(response.data);

                if (response.data["courseStartDate"] == null)
                    setCourseStartDate("");
                else
                    setCourseStartDate(new DateObject(response.data["courseStartDate"]).convert(persian, persian_fa).toString());

                // alert(courseStartDate);
                setTimeout(() => setIsLoading(false), 1000)
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    return (
        <div className="row d-flex mt-2">
            <div className="col-md-4"></div>
            {!isLoading &&
                <div className="col-md-4 col-md-border">
                    <img className="w-100" src={process.env.REACT_APP_API_URL + "/" + logoFileName} style={{ height: "300px" }} />

                    {/* <table class="table">
                        <tbody>
                            <tr>
                                <td><p>نام: {courseInfo["courseName"]}</p></td>
                                <td>Larry</td>
                            </tr>
                            <tr>
                                <td>Larry</td>
                                <td>Larry</td>
                            </tr>
                        </tbody>
                    </table> */}

                    <div className="mt-3 text-center d-flex justify-content-around">
                        <div>
                            <p className="h6">{courseInfo["courseName"]}</p>
                        </div>
                    </div>
                    <hr />
                    <div >
                        <div className="text-center d-flex justify-content-around">
                            <div>
                                <p>مربی: {courseInfo["teacherName"]} {courseInfo["teacherFamily"]}</p>
                            </div>
                            {courseStartDate != "" &&
                                <div>
                                    <p>شروع دوره: {courseStartDate}</p>
                                </div>
                            }
                        </div>
                    </div>
                    <hr />
                    {/* {courseStartDate != "" &&
                        <div className="text-center d-flex justify-content-around">
                            <div>
                                <p>شروع دوره: {courseStartDate}</p>
                            </div>
                        </div>

                    } */}

                    <div class="position-relative bottom-0 start-50 translate-middle-x mt-2">
                        {!courseInfo["isEnableRegisteraionLink"] && <input type="button" className="btn btn-danger w-100" value="ثبت نام تمام شده است"></input>}
                        {courseInfo["isEnableRegisteraionLink"] && <input onClick={() => navigate("/registerStudent/" + courseRegisterationCode)} type="button" className="btn btn-primary w-100" value="ثبت نام"></input>}
                    </div>
                </div>
            }
            <div className="col-md-4"></div>
            {isLoading &&
                <div class="spinner-border text-primary position-absolute top-50 start-50" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                // <div class="spinner-grow text-primary position-absolute top-50 start-50" role="status">
                //     <span class="visually-hidden">Loading...</span>
                // </div>
            }
        </div>
    );
};

export default ShowCourseByCourseCode;



