import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import answerService from '../../services/answers.service';
import { toast } from 'react-toastify';
import StudentInfoComponent from '../student/StudentInfoComponent';
import AnswerComponent from './AnswerComponent';


const AllAnswersOfStudentInCourseComponent = (props) => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(-1);
  const [studentId, setStudentId] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [modeNum, setModeNum] = useState(1);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setCourseId(props.courseId);
    setStudentId(props.studentId);
    GetAllAnswersOfStudentInCourse(props.studentId, props.courseId);
  }, []);

  function GetAllAnswersOfStudentInCourse(studentId, courseId) {
    setIsSearch(true);
    answerService.GetAllAnswersOfStudentInCourse(studentId, courseId).then(
      (response) => {
        setAnswers(response.data);
      },
      (error) => {

      }
    );
    setIsSearch(false);
  }

  function changeTypeHandler(value) {
    setAnswers([]);
    if (value == 1) {
      // GetAllDontCheckAnswersOfCourse(courseId);
      setModeNum(1);
    }
    else if (value == 2) {
      // GetAllAnswersOfCourse(courseId);
      setModeNum(2);
    }
  }

  return (

    <div>
      <select onChange={(e) => changeTypeHandler(e.target.value)} class="form-control w-25">
        <option value={1}>جواب های تصحیح نشده</option>
        <option value={2}>همه ی جواب ها</option>
      </select>

      {answers.length > 0 && <AnswerComponent key={modeNum} answers={answers} />}

    </div>
  )
}



export default AllAnswersOfStudentInCourseComponent;