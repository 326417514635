import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import answerService from '../../services/answers.service';
import { toast } from 'react-toastify';
import StudentInfoComponent from '../student/StudentInfoComponent';


const AnswerComponent = (props) => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    setAnswers(props.answers);
    createEmptyPointList(props.answers);
  }, []);

  function updateEarnedPoint(index, csqId, point) {
    answerService.updateEarnedPoint(csqId, point).then(
      (response) => {
        toast.success("با موفقیت ثبت شد.");
        let answs = [...answers];
        let pnts = [...points];
        answs.splice(index, 1);
        pnts.splice(index, 1);
        setAnswers(answs);
        setPoints(pnts);

      },
      (error) => {
        // toast.error("با خطا مواجه شد");
      }
    );
  }

  function createEmptyPointList(answers) {
    var aPoint = [];
    for (let i = 0; i < answers.length; i = i + 1) {
      aPoint.push({ csqId: answers[i].id, point: 0 })
    }
    setPoints(aPoint);
  }

  const handleChangePointList = (index, point) => {
    let newArr = [...points];
    newArr[index]["point"] = point;
    setPoints(newArr);

    let n1 = [...answers];
    n1[index]["earnedPoint"] = point;
    setAnswers(n1);

  };

  function btnRegisterHandle(index) {
    updateEarnedPoint(index, points[index]["csqId"], points[index]["point"]);
  }


  return (

    <div>
      {answers.map((answer, i) => {
        return <div className='border border-3 my-5'>
          <div className="mt-3 ms-2" dangerouslySetInnerHTML={{ __html: answer.question }} />
          <hr />
          {answer.answerType == 1 && <p>{answer.answer}</p>}
          {answer.answerType == 4 && <p>{answer.numberAnswer}</p>}
          {answer.answerType == 2 &&
            <div className="row">
              {answer.files.map((file, index) => {
                return (
                  <div className="col-md-3 border">
                    <p>{file.name}</p>
                    <a href={process.env.REACT_APP_API_URL + "/" + file.address} download={file.name} target='_blank'>دانلود</a>
                  </div>
                );
              })}
            </div>
          }

          {/* <hr /> */}
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4 border d-flex p-2 rounded-3 border-3 border-primary mb-2'>
              <div className='d-flex align-items-center justify-content-end'><p className='m-0'>حداکثر امتیاز: {answer.maxScore}</p></div>
              <div className='mx-2'><input onChange={(e) => handleChangePointList(i, e.target.value)} value={answer.earnedPoint} type='text' className='form-control' placeholder='امتیاز' /></div>
              <div><input onClick={() => btnRegisterHandle(i)} type='button' className='btn btn-primary' value="ثبت" /></div>
            </div>
          </div>
        </div>
      })}

      {Object.keys(answers).length === 0 &&
        <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
      }

    </div>
  )
}



export default AnswerComponent;