import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import answerService from '../../services/answers.service';
import { toast } from 'react-toastify';
import StudentInfoComponent from '../student/StudentInfoComponent';
import AnswerComponent from './AnswerComponent';


const AllDontCheckAnswersOfCourseComponent = (props) => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [modeNum, setModeNum] = useState(1);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setCourseId(props.courseId);
    GetAllDontCheckAnswersOfCourse(props.courseId);
  }, []);

  // function GetAllAnswersOfStudentInCourse(studentId, courseId) {
  //   setIsSearch(true);
  //   answerService.GetAllAnswersOfStudentInCourse(studentId, courseId).then(
  //     (response) => {
  //       setAnswers(response.data);
  //       setIsSearch(false);
  //     },
  //     (error) => {
  //       setIsSearch(false);
  //     }
  //   );

  // }

  function GetAllAnswersOfCourse(courseId) {
    setIsSearch(true);
    answerService.GetAllAnswersOfCourse(courseId).then(
      (response) => {
        setAnswers(response.data);
        setIsSearch(false);
      },
      (error) => {
        setIsSearch(false);
      }
    );
  }

  function GetAllDontCheckAnswersOfCourse(courseId) {
    setIsSearch(true);
    answerService.GetAllDontCheckAnswersOfCourse(courseId).then(
      (response) => {
        setAnswers(response.data);
        setIsSearch(false);
      },
      (error) => {
        setIsSearch(false);
      }
    );
  }

  function changeTypeHandler(value) {
    setAnswers([]);
    if (value == 1) {
      GetAllDontCheckAnswersOfCourse(courseId);
      setModeNum(1);
    }
    else if (value == 2) {
      GetAllAnswersOfCourse(courseId);
      setModeNum(2);
    }
  }

  return (

    <div>
      <div className='border d-block m-auto col-sm-4'>
        <select onChange={(e) => changeTypeHandler(e.target.value)} class="form-select ">
          <option value={1}>جواب های تصحیح نشده</option>
          <option value={2}>همه ی جواب ها</option>
        </select>
      </div>

      {answers.length > 0 && <AnswerComponent key={modeNum} answers={answers} />}
      {answers.length == 0 && isSearch == false && <p className='text-center py-2 mt-3 border'>جواب جدید نیست.</p>}
      {isSearch &&
        <div class="spinner-border text-primary d-block m-auto mt-3" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </div>
  )
}



export default AllDontCheckAnswersOfCourseComponent;