import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AzmoonListComponent from "./AzmoonListComponent";
import AzmoonAddEditComponent from "./AzmoonAddEditComponent";
import questionsService from "../../services/questions.service";
import { toast } from "react-toastify";
import QuestionDeleteComponent from "./QuestionDeleteComponent";


const QuestionAddEditComponent = (props) => {
    const openRef = useRef();
    const closeRef = useRef();


    const [azmoonId, setAzmoonId] = useState(-1);
    const [questionId, setQuestionId] = useState(-1);
    const [answerType, setAnswerType] = useState(1);
    const [isAnswerOptional, setIsAnswerOptional] = useState(false);
    const [score, setScore] = useState(0);
    const [choises, setChoises] = useState([]);
    const [htmlQuestion, setHtmlQuestion] = useState("");

    // const [choiseChange, setChoisesChange] = useState(0);

    useEffect(() => {
        setAzmoonId(props.azmoonId);
        setQuestionId(props.questionId);
        if (props.questionId != undefined && props.questionId != -1) {
            openRef.current.click();
            getQuestionById(props.questionId);
        }
    }, []);

    function getQuestionById(questionId) {
        questionsService.getQuestionById(questionId).then(
            (response) => {
                setHtmlQuestion(response.data.htmlText);
                setScore(response.data.score);
                setAnswerType(response.data.answertType);
                setChoises(response.data.multipleChoiseQuestions);
                setIsAnswerOptional(response.data.isAnswerOptional);
                // console.log(response.data.multipleChoiseQuestions);
            },
            (error) => {

            }
        );
    }

    function insertQuestion() {
        questionsService.insertQuestion(azmoonId, htmlQuestion,score, isAnswerOptional, answerType, choises).then(
            (response) => {
                props.getAzmoonWithQuestions(azmoonId);
                closeRef.current.click();
            },
            (error) => {
            }
        );
    }

    function updateQuestion(id, htmlText, score, updateQuestion, answerType, choises) {
        questionsService.updateQuestion(id, htmlText, score, isAnswerOptional, answerType, choises).then(
            (response) => {
                props.getAzmoonWithQuestions(azmoonId);
                closeRef.current.click();
            },
            (error) => {
            }
        );
    }

    function addEmptyToCHoises() {
        let newArr = [...choises];
        // setChoisesChange(choiseChange + 1);
        newArr.push({ "id": -1, "htmlText": "", "isCorrect": false });
        setChoises(newArr);
    }

    function changeChoiseHtml(index, e) {
        let newArr = [...choises];
        newArr[index]["htmlText"] = e.target.value;
        setChoises(newArr);
    }

    function changeChoiseIsCorrect(index) {
        let newArr = [...choises];

        for (let i = 0; i < newArr.length; i++) {
            newArr[i].isCorrect = false;
        }
        newArr[index].isCorrect = true;
        setChoises(newArr);
        // setChoisesChange(choiseChange + 1);
    }

    function removeFromArray(index) {
        // choises.splice(index, 1);
        let newArr = [...choises];
        newArr.splice(index, 1);
        setChoises(newArr);
    }

    function btnSaveHandler() {
        if (questionId == -1) {
            insertQuestion();
        }
        else {
            updateQuestion(questionId, htmlQuestion, score, isAnswerOptional, answerType, choises);
        }
    }

    function closeDialog() {
        props.afterCloseQuestionAddEdit();
        setHtmlQuestion("");
        setScore(0);
        setAnswerType(1);
        setChoises([]);
    }

    return (
        <div>
            {/* <button ref={openRef} type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">افزودن سوال</button> */}
            {questionId != undefined && <button ref={openRef} type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">افزودن سوال</button>}

            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button> */}

            <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
                            <button onClick={() => closeDialog()} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <input id="floatingInput1" placeholder="1" value={score} onChange={(e) => setScore(e.target.value)} type="text" className="form-control" />
                                        <label for="floatingInput1">امتیاز</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="form-floating">
                                        <select class="form-select" value={answerType} onChange={(e) => setAnswerType(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                                            <option value="1">تشریحی</option>
                                            <option value="2">فایل</option>
                                            <option value="4">عدد</option>
                                            <option value="3">چند گزینه ای</option>
                                        </select>
                                        <label for="floatingSelect">انتخاب نوع جواب</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <div class="form-floating">
                                        <select class="form-select" value={isAnswerOptional} onChange={(e) => setIsAnswerOptional(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                                            <option value="true">اختیاری</option>
                                            <option value="false">اجباری</option>
                                        </select>
                                        <label for="floatingSelect">پاسخ دادن</label>
                                    </div>
                                </div>

                            </div>

                            <div className="mt-3">
                                <div class="form-floating">
                                    <textarea value={htmlQuestion} onChange={(e) => setHtmlQuestion(e.target.value)} class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="سوال"></textarea>
                                    <label for="floatingTextarea">سوال</label>
                                </div>
                            </div>

                            {/* </div> */}
                            {/* <hr /> */}
                            {answerType == 3 &&
                                <div>
                                    <div className="mt-2 text-center">

                                        <button onClick={addEmptyToCHoises} type="button" className="btn btn-primary mt-2">افزودن گزینه</button>
                                    </div>
                                    {choises.map((choise, i) => {
                                        return <div className="d-flex  mt-3">
                                            <input onChange={() => changeChoiseIsCorrect(i)} checked={choise["isCorrect"]} class="form-check-input m-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <div class="form-floating mb-3 flex-grow-1">
                                                <textarea value={choise["htmlText"]} onChange={(e) => changeChoiseHtml(i, e)} class="form-control" id="exampleFormControlTextarea11" placeholder="g" rows="3"></textarea>
                                                <label for="exampleFormControlTextarea11">گزینه</label>
                                            </div>
                                            <button onClick={() => removeFromArray(i)} type="button" className="btn btn-danger btn-sm align-self-center p-1 ms-2">حذف</button>
                                        </div>
                                    })}


                                </div>
                            }
                        </div>
                        <div class="modal-footer text-center">
                            <button ref={closeRef} onClick={() => closeDialog()} type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
                            {/* <button type="button" class="btn btn-success">{questionId}</button> */}
                            <button onClick={btnSaveHandler} type="button" class="btn btn-primary">ذخیره</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default QuestionAddEditComponent;



