import api from "./API";

// const allAzmoonsOfLesson = (lessonId) => {
//   return api.get('/Teacher/Azmoon/AllAzmoonsOfLesson/' + lessonId);
// };

const getQuestionById = (questionId) => {
  return api.get('/Teacher/Question/GetQuestionByIdWithChoises/' + questionId);
};

const ChangeOrderOfQuestions = (azmoonId, questionsIds) => {
  return api.post('/Teacher/Question/ChangeOrderOfQuestions/' + azmoonId, questionsIds);
};

const insertQuestion = (azmoonId, htmlText, score,isAnswerOptional, answerType, choises) => {
  var data = {
    "azmoonID": azmoonId,
    "htmlText": htmlText,
    "score": parseInt(score),
    "isAnswerOptional": isAnswerOptional,
    "AnswertType": parseInt(answerType),
    "multipleChoiseQuestions": choises,
  };

  return api.post('/Teacher/Question/InsertQuestion', data);
};

const updateQuestion = (id, htmlText, score, isAnswerOptional, answerType, choises) => {
  var data = {
    "id": id,
    "htmlText": htmlText,
    "score": parseInt(score),
    "isAnswerOptional": isAnswerOptional,
    "AnswertType": parseInt(answerType),
    "multipleChoiseQuestions": choises,
  };

  return api.put('/Teacher/Question/UpdateQuestion', data);
};

const destroy = (questionId) => {
  return api.delete('/Teacher/Question/Delete/' + questionId);
};

export default {
  destroy,
  insertQuestion,
  updateQuestion,
  getQuestionById,
  ChangeOrderOfQuestions,
};
