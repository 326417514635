import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import courseStudentService from '../../services/courseStudents.service';
import { toast } from 'react-toastify';
import StudentInfoComponent from '../student/StudentInfoComponent';


const CourseStudentsComponent = (props) => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState(-1);
  const [students, setStudents] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const [isInStudentInfoPage, setIsInStudentInfoPage] = useState(false);


  const [selectedStudentId, setSelectedStudentId] = useState(-1);
  const [selectedStudent, setSelectedStudent] = useState({});

  const dialogRef = useRef();

  useEffect(() => {
    setCourseId(props.courseId);
    getStudentsOfCourse(props.courseId);
  }, []);


  function getStudentsOfCourse(courseId) {
    setIsSearch(true);
    courseStudentService.allStudentsOfCourse_Teacher(courseId).then(
      (response) => {
        setStudents(response.data);
        setIsSearch(false);
      },
      (error) => {
        setIsSearch(false);
      }
    );
  }

  // function sendSmsStudentLink(studentId) {
  //   setIsSearch(true);
  //   courseStudentService.sendSmsStudentLink(courseId, studentId).then(
  //     (response) => {
  //       toast.success("ارسال شد");
  //     },
  //     (error) => {

  //     }
  //   );
  //   setIsSearch(false);
  // }

  function openStudentLink(studentCode) {
    window.open('/course/' + studentCode, '_blank', 'noreferrer');
  }

  function selectStudent(student) {
    // setIsInStudentInfoPage(true);
    setSelectedStudent(student);
    setSelectedStudentId(student["id"]);
    dialogRef.current?.click()
  }

  const thStyle = {
    cursor: 'pointer',
  };

  return (
    <div>


      {!isInStudentInfoPage &&
        <div class="table-responsive">
          <table class="table table-hover text-center">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">نام و نام خانوادگی</th>
                <th className="text-center">موبایل</th>
                <th className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, i) => {
                return <tr>
                  <td style={thStyle} onClick={() => selectStudent(student)}>{i + 1}</td>
                  <td style={thStyle} onClick={() => selectStudent(student)}>{student["name"]} {student["family"]}</td>
                  <td style={thStyle} onClick={() => selectStudent(student)}>{student["mobile"]}</td>
                  <td className='text-end'>
                    <input value="باز کردن" type='button' onClick={() => openStudentLink(student["code"])} className='btn btn-primary btn-sm m-1'></input>
                  </td>
                </tr>
              })}

              {Object.keys(students).length === 0 && isSearch == false &&
                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
              }

              {isSearch &&
                <tr><td className="text-center" colSpan={5}>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td></tr>
              }

            </tbody>
          </table>
        </div>
      }
      <div>
        <button ref={dialogRef} type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Launch demo modal
        </button>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <StudentInfoComponent key={selectedStudentId} student={selectedStudent} courseId={courseId} />
              </div>
              {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* {isInStudentInfoPage &&

        <StudentInfoComponent studentId={selectedStudentId} courseId={courseId} />
      } */}
    </div>

  )
}



export default CourseStudentsComponent;