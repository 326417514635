import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import academyTeachers from "../../services/academyTeachers.service";
import { useDispatch } from "react-redux";
import { logout } from "./../../actions/auth";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import StudentsComponent from './StudentsComponent'

const TeachersComponent = (props) => {
    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAcademyTeachers();
    }, []);

    function getAcademyTeachers() {
        setIsLoading(true);
        academyTeachers.allTeachersOfAcademy().then(
            (response) => {
                setTeachers(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );

    }

    return (
        <div className="w-100">
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p></p>
                        <Link to="/add-teacher">
                            <button type="button" class="btn btn-primary">افزودن مربی جدید</button>
                        </Link>
                    </div>
                </Title>
                <Content>
                    <div class="table-responsive">
                        <table class="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>نام</th>
                                    <th>نام خانوادگی</th>
                                    <th>نام کاربری</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {teachers.map((teacher, i) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        <td>{teacher["name"]}</td>
                                        <td>{teacher["family"]}</td>
                                        <td>{teacher["mobile"]}</td>
                                        <td style={{ whiteSpace: "nowrap", width: "1px" }}>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a class="dropdown-item" href="#">حذف</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                })}

                                {Object.keys(teachers).length === 0 && !isLoading &&
                                    <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                                }

                                {isLoading &&
                                    <tr><td className="text-center" colSpan={5}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td></tr>
                                }

                            </tbody>
                        </table>
                    </div>

                </Content>
        </div>
    );
};

export default TeachersComponent;



