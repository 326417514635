import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import academyCoursesService from "../../services/course.service";
import CourseStudents from "./components/CourseStudents";
import AddCourseComponent from "./AddCourse-component";
import Popup from "reactjs-popup";

const AddCoursePage = () => {
    const { state } = useLocation();
    const [courseId, setCourseId] = useState(-1);
    const [selectedStatusID, setSelectedStatusID] = useState(-1);
    const [courseName, setCourseName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [showPrice, setShowPrice] = useState("");
    const [logoFileName, setLogoFileName] = useState("");
    const [courseSubText, setCourseSubText] = useState("");
    const [registeraionLink, setRegisteraionLink] = useState("");
    const [isEnableRegisteraionLink, setIsEnableRegisteraionLink] = useState(false);
    const [teachers, setTeachers] = useState([]);
    const [students, setStudents] = useState([]);
    const [isToggleEnableRegisterationLink, setIsToggleEnableRegisterationLink] = useState(false);
    const [verifyStudentAutomation, setVerifyStudentAutomation] = useState(false);
    const [sendRegistrationlinkAutomation, setSendRegistrationlinkAutomation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (state.courseId != -1) {
            setCourseId(state.courseId);
            getCourseById(state.courseId);
        }
        else {
            setIsLoading(true);
        }
    }, []);


    function getCourseById(courseId) {
        academyCoursesService.getCourseById(courseId).then(
            (response) => {
                setCourseName(response.data.name);
                setRegisteraionLink(response.data.registeraionLink);
                setSelectedStatusID(response.data.status);
                setShowPrice(response.data.showPrice);
                setStartDate(response.data.startDate);
                setTeachers(response.data.teachers);
                setCourseSubText(response.data.htmlSubText);
                setStudents(response.data.students);
                setVerifyStudentAutomation(response.data.verifyStudentAutomation);
                setSendRegistrationlinkAutomation(response.data.sendRegistrationlinkAutomation);
                setLogoFileName(response.data.logoFileName);
                setIsEnableRegisteraionLink(response.data.isEnableRegisteraionLink);
                setIsLoading(true);
            },
            (error) => {

            }
        );
    }

    function toggleEnableRegisterationLink() {
        setIsToggleEnableRegisterationLink(true);
        academyCoursesService.toggleEnableRegisterationLink(courseId).then(
            (response) => {
                setIsEnableRegisteraionLink(!isEnableRegisteraionLink);
                setIsToggleEnableRegisterationLink(false);
            },
            (error) => {
                setIsToggleEnableRegisterationLink(false);
            }
        );
    }

    function toggleVerifyStudentAutomation() {
        // setIsToggleEnableRegisterationLink(true);
        academyCoursesService.toggleVerifyStudentAutomation(courseId).then(
            (response) => {
                setVerifyStudentAutomation(response.data.verifyStudent);
                // setIsToggleEnableRegisterationLink(false);
            },
            (error) => {
                // setIsToggleEnableRegisterationLink(false);
            }
        );
    }

    function toggleSendCourseLinkAutomation() {
        // setIsToggleEnableRegisterationLink(true);
        academyCoursesService.toggleSendCourseLinkAutomation(courseId).then(
            (response) => {
                setSendRegistrationlinkAutomation(response.data.sendRegistrationlink);
                // setIsToggleEnableRegisterationLink(false);
            },
            (error) => {
                // setIsToggleEnableRegisterationLink(false);
            }
        );
    }

    function courseLinkCopy() {
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "/sc/" + registeraionLink);
        toast.info("کپی شد.");
    }

    function enableRegisteraionLinkHandler() {
        toggleEnableRegisterationLink();
    }

    return (
        <div>
            {/* <Card>
                <Content>
                    <ol class="stepper">
                        <li>تعریف درس توسط مدیر</li>
                        <li>ثبت نام هنرآموز</li>
                        <li>تایید هنرآموز توسط مدیر</li>
                        <li class="active">ارسال لینک درس به هنرآموز</li>
                    </ol>
                </Content>
            </Card> */}
            <div className="row" style={{ display: 'flex' }}>
                <div class="col-md-4 mt-3">
                    <Card >
                        <Title>
                            <p>مشخصات دوره</p>
                        </Title>
                        <Content>
                            {isLoading && <AddCourseComponent courseId={courseId} statusId={selectedStatusID} courseName={courseName} startDate={startDate} courseTeachers={teachers} logoFileName={logoFileName} showPrice={showPrice} courseSubText={courseSubText}/>}
                        </Content>
                    </Card>
                </div>
                {courseId !== -1 &&
                    <div className="col-md-8 mt-3">
                        <Card class="col-md-4">
                            <Title>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginLeft: '10px' }}>تنظیمات دوره</p>
                                    <iconify-icon icon="material-symbols:help-outline" width="20" height="20" style={{ color: "black" }}></iconify-icon>
                                </div>
                            </Title>
                            <Content>
                                <div >
                                    <div className="d-flex justify-content-between border p-2 mt-2">
                                        {!isToggleEnableRegisterationLink &&
                                            <div class="form-check form-switch">
                                                < input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onChange={enableRegisteraionLinkHandler} checked={isEnableRegisteraionLink ? 'checked' : ''} />
                                                <label class="form-check-label" for="flexSwitchCheckDefault1">فعال بودن ثبت نام</label>
                                            </div>
                                        }
                                        {isToggleEnableRegisterationLink &&
                                            <div class="spinner-border spinner-border-sm p-0 m-0" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        }

                                        <div className="overflow-hidden">
                                            <iconify-icon icon="solar:copy-linear" onClick={() => courseLinkCopy()} width="16" height="16" style={{ color: "black" }}></iconify-icon>
                                            <a href={process.env.REACT_APP_STUDENT_URL + "/sc/" + registeraionLink} target="_blank"> {process.env.REACT_APP_STUDENT_URL + "/sc/" + registeraionLink}</a>
                                        </div>
                                    </div>
                                </div>
                            </Content>
                        </Card>
                        <br /><br />
                        <Card class="col-md-4">
                            <Title>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginLeft: '10px' }}>خودکارسازی</p>
                                    <iconify-icon icon="material-symbols:help-outline" width="20" height="20" style={{ color: "black" }}></iconify-icon>
                                </div>
                            </Title>
                            <Content>
                                <div >
                                    <div class="row border pt-3 ps-3 mt-2">
                                        <div className="col-md-6 form-check form-switch">
                                            < input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" onChange={() => toggleVerifyStudentAutomation()} checked={verifyStudentAutomation ? 'checked' : ''} />
                                            <label class="form-check-label" for="flexSwitchCheckDefault2">تایید خودکار</label>
                                        </div>
                                        <div className="col-md-6 form-check form-switch">
                                            < input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" onChange={toggleSendCourseLinkAutomation} checked={sendRegistrationlinkAutomation ? 'checked' : ''} />
                                            <label class="form-check-label" for="flexSwitchCheckDefault3">ارسال خودکار لینک درس بعد از تایید</label>
                                        </div>
                                    </div>
                                </div>
                            </Content>
                        </Card>
                        <br /><br />
                        <Card class="col-md-4">
                            <Content>
                                {isLoading && <CourseStudents courseId={courseId} students={students} />}
                            </Content>
                        </Card>
                    </div>
                }
                {courseId === -1 &&
                    <div className="col-md-8 text-center">
                        <p>افزودن کلاس جدید</p>
                        {/* <iconify-icon icon="arcticons:classroom" width="32" height="32"  style={{color: "black"}}></iconify-icon> */}
                    </div>
                }
            </div>
        </div>
    );
};

export default AddCoursePage;



