
import Content from '../../components/Layout/card/Content';
import Title from '../../components/Layout/card/Title';
import Card from '../../components/Layout/card/Card';

import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import imageService from "../../services/image.service";
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';


const ImageDeleteComponent = (props) => {
    const closeRef = useRef();
    const [imageName, setImageName] = useState("");
    const [isTwoClick, setIsTwoClick] = useState(false);

    useEffect(() => {
        var filename = props.imageName.replace(/^.*[\\/]/, '');
        setImageName(filename);
    }, []);

    function deleteImage() {
        imageService.destroy(imageName).then(
            (response) => {
                // window.location.reload();
                props.getImages();
                // closeRef.current.click();
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    function deleteImageHandler() {
        if (!isTwoClick) {
            setIsTwoClick(true);
        }
        else {
            deleteImage();
        }
    }

    function closeDeleteImageHandler() {
        setIsTwoClick(false);
    }

    return (
        <div>
            <div class="text-center">
                {!isTwoClick && <button className='btn btn-sm btn-danger ms-3 py-0' style={{ cursor: 'pointer' }} onClick={() => deleteImageHandler()}><span class="gridicons--trash"></span></button>}
                {isTwoClick &&
                    <div className='d-flex border p-1 mx-2'>
                        <button className='btn btn-sm btn-info' style={{ cursor: 'pointer' }} onClick={() => deleteImageHandler()}><span class="mdi--tick"></span></button>
                        <button className='btn btn-sm btn-info ms-3' style={{ cursor: 'pointer' }} onClick={() => closeDeleteImageHandler()}><span class="material-symbols--close"></span></button>
                    </div>
                }
            </div>

            {/* <div class="modal fade" id="lessonDeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">حذف جلسه</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            آیا از حذف جلسه مطمئن هستید؟
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>خیر</button>
                            <button type="button" class="btn btn-primary" onClick={() => deleteImageHandler()}>بله</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ImageDeleteComponent;



