import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import courseStudentsService from "../../services/courseStudents.service";


const LessonsListForStudentPage = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { studentCourseCode } = useParams();

    const [lessons, setLessons] = useState([]);

    useEffect(() => {
        setLessons(props.lessons);
    }, []);



    function goToLessonViewerPage(lessonId) {
        navigate("/view/" + studentCourseCode + "/" + lessonId);
        // window.open('/view/:studentCourseCode/:lessonId' + htmlContent.id, '_blank', 'noreferrer');
    }

    return (
        <div>
            <div class="list-group">

                {lessons.map((lesson, i) => {
                    return <a class="list-group-item bg-light list-group-item-action list-group-item-info py-3 d-flex justify-content-between" onClick={() => goToLessonViewerPage(lesson.id)} style={{ cursor: 'pointer' }}>
                        {lesson.title}
                        <span className="text-primary">جدید</span>
                    </a>
                })}
            </div>

            {Object.keys(lessons).length === 0 && !isLoading &&
                <p className="position-absolute top-50 w-100 text-center">هیچ جلسه ای فعال نیست</p>
            }

            <div className={isLoading ? "position-absolute top-50 start-50 translate-middle" : "d-none"} >
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

        </div>
    );
};

export default LessonsListForStudentPage;



