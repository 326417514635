import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import teacherBooksService from "../../services/teacherBooks.service";
import bookService from "../../services/books.service";

import OrderLessonsComponent from "./OrderLessonsComponent";
import AddTeacherToBookComponent from "./AddTeacherToBookComponent";
import booksService from "../../services/books.service";

const AddBookPage = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [bookId, setBookId] = useState(-1);
    const [lessons, setLessons] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [bookName, setBookName] = useState("");
    const [bookDescription, setBookDescription] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (state.bookId != -1) {
            // alert("1->" + state.bookId)
            setBookId(state.bookId);
            getBookWithLessons(state.bookId);
        }
    }, []);

    function getBookWithLessons(bookId) {
        bookService.getBookWithLessons(bookId).then(
            (response) => {
                setValuesAfterGet(response.data.data);
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    function setValuesAfterGet(data) {
        setBookName(data.name);
        setBookDescription(data.description);
        setLessons(data.lessons);
        setTeachers(data.teachers);
    }

    function insertBookToTeacher() {
        setIsLoading(true);
        teacherBooksService.insertBookToTeacher(bookName, bookDescription).then(
            (response) => {
                navigate("/books");
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsLoading(false);
    }

    function updateBook() {
        setIsLoading(true);
        teacherBooksService.updateBook(bookId, bookName, bookDescription).then(
            (response) => {
                toast.success("موفقیت آمیز");
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
        setIsLoading(false);
    }


    function removeBookFromTeacher(mobileToRemove) {
        booksService.removeBookFromTeacher(bookId, mobileToRemove).then(
            (response) => {
                setValuesAfterGet(response.data.data);
            },
            (error) => {
            }
        );
    }

    function btnAddHandler() {
        if (bookId == -1)
            insertBookToTeacher();
        else
            updateBook();
    }

    // function goToEditorPage(lessonId) {
    //     navigate("/lesson-editor");
    // }

    function removeLesson(lessonId) {
        // teacherBooksService.insertBookToTeacher(bookName, bookDescription).then(
        //     (response) => {
        //         navigate("/books");
        //     },
        //     (error) => {
        //         // toast.error(error.response.data.message);
        //     }
        // );
    }


    function goToBooksListPage() {
        navigate('/books');
    }

    function bookNameChange(e) {
        setBookName(e.target.value);
    }

    function bookDescriptionChange(e) {
        setBookDescription(e.target.value);
    }

    return (
        <div>
            <div className="my-2 d-flex justify-content-between">
                <div className="">
                </div>
                {/* <p>برگشت</p> */}
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Card>
                        <Title>
                            <div>
                                <p>مشخصات درس</p>
                            </div>
                        </Title>
                        <Content>
                            <div class="form-horizontal form-label-left">
                                <div class="form-group">
                                    <label for="first-name"> نام درس * </label>
                                    <input value={bookName} onChange={bookNameChange} type="text" id="first-name" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">توضیحات</label>
                                    <div>
                                        <textarea value={bookDescription} onChange={bookDescriptionChange} cols="40" type="text" id="last-name" name="last-name" required="required" class="form-control col-md-7 col-xs-12" />
                                    </div>
                                </div>
                                <br />

                                <div class="form-group">
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary" onClick={btnAddHandler}>ثبت</button>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card>
                        <Title>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>مربیان</p>
                                <div>
                                    {bookId != -1 && <AddTeacherToBookComponent bookId={bookId} afterInsert={(data)=>setValuesAfterGet(data)} />}

                                    {/* <button onClick={() => goToBooksListPage()} type="button" className="btn btn-primary text-white ml-5">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                        </svg>

                                    </button> */}
                                </div>
                            </div>
                        </Title>
                        <Content>

                            <table class="table table-hover text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>نام</th>
                                        <th>فامیل</th>
                                        <th>موبایل</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teachers.map((teacher, i) => {
                                        return <tr>
                                            <td style={{ cursor: 'pointer', width: '100px' }}>{i + 1}</td>
                                            <td style={{ cursor: 'pointer', width: '100px' }}>{teacher.name}</td>
                                            <td style={{ cursor: 'pointer', width: '100px' }}>{teacher.family}</td>
                                            <td style={{ cursor: 'pointer', width: '100px' }}>{teacher.mobile}</td>
                                            <td style={{ cursor: 'pointer', width: '100px' }}>
                                                <button onClick={() => removeBookFromTeacher(teacher.mobile)} className="btn btn-sm btn-danger">حذف</button>
                                            </td>
                                        </tr>
                                    })}

                                    {Object.keys(teachers).length === 0 && !isLoading &&
                                        <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                                    }

                                    {isLoading &&
                                        <tr><td className="text-center" colSpan={6}>
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td></tr>
                                    }
                                </tbody>
                            </table>
                            {/* {teachers.map((teacher, i) => {
                                return <option value={teacher.id}>{teacher.name}</option>;
                            })} */}
                        </Content>
                    </Card>
                    <br />
                    <br />
                    <Card>
                        <Title>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>مفاهیم</p>
                                <div>
                                    {/* {bookId !== -1 && <AddLessonComponent bookId={bookId} getLessons={getBookWithLessons} />} */}
                                    <button onClick={() => goToBooksListPage()} type="button" className="btn btn-primary text-white ml-5">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                        </svg>

                                    </button>
                                </div>
                            </div>
                        </Title>
                        <Content>
                        </Content>
                    </Card>
                </div>
            </div>

        </div>
    );
};

export default AddBookPage;



