import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import academyTeachers from "../../services/academyTeachers.service";
import { useDispatch } from "react-redux";
import { logout } from "./../../actions/auth";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import StudentsComponent from './StudentsComponent'
import TeachersComponent from "./TeachersComponent";

const UsersPage = (props) => {
    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

    }, []);


    return (
        <div className="w-100">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>کاربران آموزشگاه</p>
                    </div>
                </Title>
                <Content><Tabs>
                    <TabList>
                        <Tab>مربیان</Tab>
                        <Tab>هنرآموزان</Tab>
                    </TabList>
                    <TabPanel>
                        <TeachersComponent />
                    </TabPanel>
                    <TabPanel>
                        <StudentsComponent />
                    </TabPanel>
                </Tabs>
                </Content>

            </Card>
        </div>
    );
};

export default UsersPage;



