import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WebsiteAddComponent from "./WebsiteAddComponent";
import WebsiteListComponent from "./WebsiteListComponent";
import { useState } from "react";
import WebsiteSettingPage from "./WebsiteSettingPage";

const WebsitePage = (props) => {
    const [isInAddPageMode, setIsInAddPageMode] = useState(false);
    const [editPageId, setEditPageId] = useState(-1);

    function goToUpdatePage(pageId) {
        setEditPageId(pageId);
        setIsInAddPageMode(true);
    }

    function btnAddPageHandler() {
        setEditPageId(-1);
        setIsInAddPageMode(true);
    }

    function handleEditButton() {
        setIsInAddPageMode(false);
    }

    return (
        <div className="w-100">
            <Card>
                <Title>
                    مدیریت سایت
                </Title>
                <Content>
                    <Tabs>
                        <TabList>
                            <Tab>صفحات سایت</Tab>
                            <Tab>آمار بازدید</Tab>
                            <Tab>تنظیمات</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="text-center">
                                {isInAddPageMode &&
                                    < button onClick={() => handleEditButton()} type="button" className="btn btn-success btn-sm mx-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ویرایش">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </button>
                                }
                                {/* {!isInAddPageMode &&
                                } */}
                                {!isInAddPageMode &&
                                    <div>
                                        <WebsiteListComponent goToUpdatePage={goToUpdatePage} />
                                        <input onClick={btnAddPageHandler} type="button" className="btn btn-primary btn-sm mx-2" value="افزودن"></input>
                                    </div>
                                }
                                {isInAddPageMode &&
                                    <WebsiteAddComponent pageId={editPageId} />
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>

                        </TabPanel>
                        <TabPanel>
                            <WebsiteSettingPage />
                        </TabPanel>
                    </Tabs>
                </Content>

            </Card>
        </div>
    );
};

export default WebsitePage;



