import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import LessonsListForStudentComponent from './LessonsListForStudentComponent';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AllDontCheckAnswersOfCourseComponent from '../teacher/AllDontCheckAnswersOfCourseComponent';
import AllAnswersOfStudentInCourseComponent from '../teacher/AllAnswersOfStudentInCourseComponent';
import lessonService from '../../services/lesson.service';


const LessonsOfStudentInCourseComponent = (props) => {
    const [lessons, setLessons] = useState([]);
    const [studentId, setStudentId] = useState(-1);
    const [courseId, setCourseId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setStudentId(props.studentId)
        setCourseId(props.courseId)
        getLessonsOfStudentInCourse(props.studentId, props.courseId);
    }, []);

    function getLessonsOfStudentInCourse(studentId, courseId) {
        lessonService.getLessonsOfStudentInCourse(studentId, courseId).then(
            (response) => {
                setLessons(response.data);
            },
            (error) => {
            }
        );
    }

    function EnableLessonForStudent(lessonId) {
        lessonService.EnableLessonForStudent(studentId, courseId, lessonId).then(
            (response) => {
                getLessonsOfStudentInCourse(studentId, courseId);
            },
            (error) => {

            }
        );
    }

    function DisableLessonForStudent(lessonId) {
        lessonService.DisableLessonForStudent(studentId, courseId, lessonId).then(
            (response) => {
                getLessonsOfStudentInCourse(studentId, courseId);
            },
            (error) => {

            }
        );
    }
    return (
        <div class="table-responsive">
            <table class="table table-hover text-center">
                <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">عنوان</th>
                        <th className="text-center">برچسب ها</th>
                        <th className="text-center"></th>

                    </tr>
                </thead>
                <tbody>
                    {lessons.map((lesson, i) => {
                        return <tr>
                            <td>{i + 1}</td>
                            <td >{lesson["title"]}</td>
                            <td>{lesson["tags"]}</td>
                            <td className='text-end'>
                                {!lesson["enabledAt"] && <button onClick={() => EnableLessonForStudent(lesson["id"])} type='button' className='btn btn-primary btn-sm'>فعال کردن</button>}
                                {lesson["enabledAt"] && <button onClick={() => DisableLessonForStudent(lesson["id"])} type='button' className='btn btn-danger btn-sm'>غیرفعال کردن</button>}
                            </td>
                        </tr>
                    })}

                    {Object.keys(lessons).length === 0 && isLoading == false &&
                        <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                    }

                    {isLoading &&
                        <tr><td className="text-center" colSpan={15}>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td></tr>
                    }

                </tbody>
            </table>
        </div>
    );
};

export default LessonsOfStudentInCourseComponent;



