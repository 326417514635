import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useState } from "react";
import courseService from "../../services/course.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import teacherBooksService from "../../services/teacherBooks.service";

const CourseSettingComponent = (props) => {
    const [courseId, setCourseId] = useState(-1);
    const [books, setBooks] = useState([]);
    const [selectedBookID, setSelectedBookID] = useState(-1);
    const [isSearch, setIsSearch] = useState(false);


    useEffect(() => {
        setCourseId(props.courseId);
        getBooksOfTeacher();
    }, []);

    function getBooksOfTeacher() {
        setIsSearch(true);
        teacherBooksService.allBooksOfTeacher().then(
            (response) => {
                setBooks(response.data);
                setSelectedBookID(response.data[0].id);
            },
            (error) => {

            }
        );
        setIsSearch(false);
    }

    function updateBookOfCourse(bookId) {
        setIsSearch(true);
        courseService.updateBookOfCourse(courseId, bookId).then(
            (response) => {

            },
            (error) => {

            }
        );
        setIsSearch(false);
    }

    function bookInputChange(e) {
        setSelectedBookID(e.target.value);
    }

    return (
        <div className="row">
            <br />
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <span class="control-label">اختصاص درس</span>
                <select onChange={bookInputChange} value={selectedBookID} class="form-select" aria-label="Default select example" id="teacher-name">
                    {books.map((teacher, i) => {
                        return <option value={teacher["id"]}>{teacher["name"]} {teacher["family"]}</option>
                    })}
                </select>
            </div>
            <div className="col-md-4"></div>

            <br />
            <br />
            <br />
            <br />
            <div className="text-center">
                <input type="button" className="btn btn-primary" value="ذخیره" onClick={() => updateBookOfCourse(selectedBookID)} />
            </div>
        </div>
    );
};

export default CourseSettingComponent;



