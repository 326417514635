import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import lessonService from '../../services/lesson.service';
import LessonAddComponent from './LessonAddComponent';
import { ReactSortable } from "react-sortablejs";
import { toast } from 'react-toastify';

// interface ItemType {
//   id: number;
//   name: string;
// }

const OrderLessonsComponent = (props) => {
  const closeRef = useRef();
  const navigate = useNavigate();
  const [bookId, setBookId] = useState(-1);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState([
    { id: 1, name: "shrek" },
    { id: 2, name: "fiona" },
  ]);

  useEffect(() => {
    setLessons(props.lessons);
    setBookId(props.bookId);
  }, []);


  function updateOrderToserver() {
    let result = lessons.map(a => a.id);
    lessonService.ChnageOrderOfLessons(bookId, result).then(
      (response) => {
        closeRef.current.click();
      },
      (error) => {
        // toast.error("با خطا مواجه شد");
      }
    );
  }

  function updateOrder() {
    let result = lessons.map(a => a.id);
    console.log(result);

  }

  function goToLessonPage(lessonId, lessonName) {
    props.selectedLessonChange(lessonId, lessonName);
    closeRef.current.click();
  }

  function deleteLesson(lessonId) {
    // alert(lessonId);
  }

  const tdStyle = {
    cursor: 'pointer',
    flex: '1',
  }


  return (
    <div>
      <button type="button" className="btn btn-primary btn-md me-2" data-bs-toggle="modal" data-bs-target="#lesseonListModal" value="مدیریت جلسات">
        {/* <iconify-icon icon="oui:app-management" width="25" height="25" style={{ color: "white" }}></iconify-icon> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
        </svg>
      </button>
      <div class="modal fade" id="lesseonListModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-around">
              <button className='btn btn-primary' onClick={() => updateOrderToserver()}>ذخیره</button>
              <button ref={closeRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">


              <div className='d-flex'>
                {/* <td>#</td> */}
                <td style={{ width: '45%', textAlign: 'center' }}>عنوان</td>
                <td style={{ width: '45%', textAlign: 'center' }}>برچسب</td>
              </div>

              <ReactSortable multiDrag list={lessons} setList={setLessons} filter=".exclude" onEnd={() => updateOrder()}>
                {lessons.map((lesson, i) => (
                  <div key={lesson["id"]} className='border d-flex align-items-center mb-2' style={{ backgroundColor: '#fff' }}>
                    {/* <div className='d-flex'> */}
                    <svg style={{ cursor: 'move' }} className='ps-3' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                    <td style={{ cursor: 'pointer', width: '45%' }} className='text-center py-3' onClick={() => goToLessonPage(lesson["id"], lesson["title"])}>{lesson["title"]}</td>
                    {/* </div> */}

                    <td style={{ cursor: 'pointer', width: '45%' }} className='py-3' onClick={() => goToLessonPage(lesson["id"], lesson["title"])}>{lesson["tags"]}</td>

                    <td style={{ cursor: 'pointer' }} className='exclude1' onClick={() => deleteLesson(lesson["id"], lesson["title"])}>
                      <div class="dropdown">
                        <svg class="dropdown-toggle bi bi-three-dots-vertical" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        </svg>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </a></li>
                        </ul>
                      </div>
                    </td>
                  </div>
                ))}
              </ReactSortable>

              {/* {lessons.map((lesson, i) => {
                      return <tr>
                        <td style={{ cursor: 'pointer' }} onClick={() => goToLessonPage(lesson["id"], lesson["title"])}>{i + 1}</td>
                        <td style={{ cursor: 'pointer' }} onClick={() => goToLessonPage(lesson["id"], lesson["title"])}>{lesson["title"]}</td>
                        <td style={{ cursor: 'pointer' }} onClick={() => goToLessonPage(lesson["id"], lesson["title"])}>{lesson["tags"]}</td>
                        <td style={{ cursor: 'pointer', width: "1px" }} onClick={() => deleteLesson(lesson["id"], lesson["title"])}><iconify-icon icon="gridicons:trash" width="32" height="32" style={{ color: "red" }}></iconify-icon></td>
                      </tr>
                    })} */}

              {Object.keys(lessons).length === 0 &&
                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
              }

              {isLoading &&
                <tr><td className="text-center" colSpan={6}>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td></tr>
              }

              {/* </tbody>
                </table>
              </div> */}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary d-none" data-bs-dismiss="modal" ref={closeRef}>بستن</button>
              {/* <button type="button" class="btn btn-primary m-auto">افزودن جلسه</button> */}
              {/* <LessonAddComponent /> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}



export default OrderLessonsComponent;