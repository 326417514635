import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AzmoonListComponent from "./AzmoonListComponent";
import AzmoonAddEditComponent from "./AzmoonAddEditComponent";
import questionsService from "../../services/questions.service";
import { toast } from "react-toastify";


const QuestionDeleteComponent = (props) => {
    const openRef = useRef();
    const closeRef = useRef();

    const [azmoonId, setAzmoonId] = useState(-1);
    const [questionIdToDelete, setQuestionIdToDelete] = useState(-1);


    useEffect(() => {
        setAzmoonId(props.azmoonId);
        setQuestionIdToDelete(props.questionId);
        if (props.questionId != undefined && props.questionId != -1) {
            openRef.current.click();
        }
    }, []);

    function deletQuestion(questionId) {
        questionsService.destroy(questionId).then(
            (response) => {
                props.getAzmoonWithQuestions(azmoonId);
                closeRef.current.click();
            },
            (error) => {
            }
        );
    }

    function btnDeleteHandler() {
        deletQuestion(questionIdToDelete);
    }

    return (
        <div>
            <button ref={openRef} type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#deleteQuestionModel">
                Launch demo modal
            </button>

            <div class="modal fade" id="deleteQuestionModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">حذف</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            آیا از حذف اطمینان دارید؟
                        </div>
                        <div class="modal-footer">
                            <button ref={closeRef} type="button" class="btn btn-secondary" data-bs-dismiss="modal">خیر</button>
                            <button onClick={() => btnDeleteHandler()} type="button" class="btn btn-primary">بله</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionDeleteComponent;



