
import Content from '../../components/Layout/card/Content';
import Title from '../../components/Layout/card/Title';
import Card from '../../components/Layout/card/Card';

import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
// import academyCourseService from "../../services/academyCourses.service";
import Popup from "reactjs-popup";
import { useEffect, useRef, useState } from 'react';
import lessonService from '../../services/lesson.service';
import { toast } from 'react-toastify';


const LessonDeleteComponent = (props) => {
    const closeRef = useRef();

    const [lessonId, setLessonId] = useState(-1);

    useEffect(() => {
        // console.log(props.bookId); //dont remove this
        setLessonId(props.lessonId);
    }, []);

    function deleteLesson() {
        lessonService.remove(lessonId).then(
            (response) => {
                window.location.reload();
                props.getLessons();
                closeRef.current.click();
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    return (
        <div>

            <div class="text-center"><input type='button' class='btn btn-danger' value="حذف جلسه" data-bs-toggle="modal" data-bs-target="#lessonDeleteModal" /></div>
            {/* <input type="button" className="btn btn-primary btn-md me-2" data-bs-toggle="modal" data-bs-target="#lessonAddModal" value="افزودن جلسه"></input> */}

            <div class="modal fade" id="lessonDeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">حذف جلسه</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            آیا از حذف جلسه مطمئن هستید؟
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>خیر</button>
                            <button type="button" class="btn btn-primary" onClick={() => deleteLesson()}>بله</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonDeleteComponent;



