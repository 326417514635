import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const allStudentsOfCourse = (courseId) => {
  return api.get('/Manager/Student/AllStudentsOfCourse/' + courseId);
};

const allStudentsOfCourse_Teacher = (courseId) => {
  return api.get('/Teacher/Course/AllStudentsOfCourse/' + courseId);
};

const addStudentToCourse = (courseId, studentId) => {
  return api.get('/Manager/Student/AddStudentToCourse/' + courseId + '/' + studentId);
};

const removeStudentFromCourse = (courseId, studentId) => {
  return api.get('/Manager/Student/RemoveStudentFromCourse/' + courseId + '/' + studentId);
};

const changeStudentInCourseToVerified = (courseId, studentId) => {
  return api.get('/Manager/Student/ChangeStudentInCourseToVerified/' + courseId + '/' + studentId);
};

const getDetailsOfStudentCode = (studentCourseCode) => {
  return api.get('Student/Course/GetDetailsOfStudentCode?code=' + studentCourseCode);
};

const getLessonByLessonIdAndStudentCode = (studentCourseCode, lessonId) => {
  return api.get('Student/Course/GetLessonByIdAndStudentCode?code=' + studentCourseCode + "&lessonId=" + lessonId);
};

const sendSmsStudentLink = (courseId, studentId) => {
  return api.get('Manager/Student/SendSmsStudentLink/' + courseId + "/" + studentId);
};

export default {
  sendSmsStudentLink,
  getLessonByLessonIdAndStudentCode,
  allStudentsOfCourse_Teacher,
  getDetailsOfStudentCode,
  addStudentToCourse,
  removeStudentFromCourse,
  allStudentsOfCourse,
  changeStudentInCourseToVerified,
};
