import { Editor } from "@tinymce/tinymce-react";

import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import lessonService from "../../services/lesson.service";
import htmlContentService from "../../services/htmlContent.service";
import { ToastContainer, toast } from "react-toastify";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LessonSettingComponent from "./LessenSettingComponent";
import SecondaryContentComponent from "./SecondaryContentComponent";
import booksService from "../../services/books.service";
import LessonAddComponent from "./LessonAddComponent";
import OrderLessonsComponent from "./OrderLessonsComponent";
import LessonContentComponent from "./LessonContentComponent";
import AzmoonListComponent from "./AzmoonListComponent";
import AzmoonPage from "./AzmoonPage";

const LessonPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(0);
    const [lesson, setLesson] = useState([]);
    // const [mainHtmlContent, setMainHtmlContent] = useState([]);
    // const [htmlContents, setHtmlContents] = useState([]);
    const [bookId, setBookId] = useState(-1);
    const [bookName, setBookName] = useState("");
    const [lessons, setLessons] = useState([]);
    const [selectedLessonId, setSelectedLessonId] = useState(-1);
    const [selectedLessonName, setSelectedLessonName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [counterForKey, setCounterForKey] = useState(0);

    useEffect(() => {
        setBookId(location.state.bookId);
        // alert(location.state.bookId);
        getBookWithLessons();
    }, []);


    function getBookWithLessons(isRefresh) {
        booksService.getBookWithLessons(location.state.bookId).then(
            (response) => {
                if (isRefresh)
                    setSelectedTab(0);

                setLessons(response.data.data.lessons);
                setBookName(response.data.data.name);
                if (response.data.data.lessons.length > 0)
                    getLessonByIdWithHtmlContent(response.data.data.lessons[0].id);
            },
            (error) => {

            }
        );
    }

    function getLessonByIdWithHtmlContent(lessonId) {
        setIsLoading(true);
        lessonService.getLessonByIdWithHtmlContent(lessonId).then(
            (response) => {
                setLesson(response.data.lesson);
                // setMainHtmlContent(response.data.htmlContents[0]);
                // setHtml(response.data.htmlContents[0].htmlText);
                // setCss(response.data.htmlContents[0].css);
                setTimeout(() => setIsLoading(false), 1000)
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function selectedLessonChangeHnadler(e) {
        selectedLessonChange(e.target.value, e.target.selectedOptions[0].text);
    }

    function selectedLessonChange(lessonId, lessonName) {
        setSelectedLessonId(lessonId);
        setSelectedLessonName(lessonName);
        setSelectedTab(0);
        getLessonByIdWithHtmlContent(lessonId);
    }

    function goToLessonEditorPage() {
        navigate('/lesson-editor', { state: { htmlContentId: lesson.htmlContents[0]["id"], bookId: lesson["bookID"] } });
    }

    function goToPreviewPage() {
        window.open('/lesson-preview/' + lesson.htmlContents[0]["id"], '_blank', 'noreferrer');
    }

    function goToSlidePage() {
        window.open('/lesson-slide/' + lesson.htmlContents[0]["id"], '_blank', 'noreferrer');
    }

    function goToBooksListPage() {
        navigate('/books');
    }

    function tabSelectedHandler(index) {
        setSelectedTab(index);
        setCounterForKey(counterForKey + 1);
    }

    return (
        <div style={{ minHeight: "100vh" }}>
            <div className="my-2">
                <a onClick={() => goToBooksListPage()} style={{ cursor: 'pointer' }}>درس های من</a>
                <a> / </a>
                <a style={{ cursor: 'pointer' }}>{bookName}</a>
                <a> / </a>
                <a style={{ cursor: 'pointer' }}>{selectedLessonName}</a>
            </div>

            <Card>
                <div className="row">
                    <div className="d-flex col-md-3"></div>
                    <div className="d-flex col-md-6">
                        {bookName != "" && <OrderLessonsComponent lessons={lessons} bookId={bookId} getLessons={getBookWithLessons} selectedLessonChange={selectedLessonChange} />}
                        {bookId != -1 && <LessonAddComponent bookId={bookId} getLessons={getBookWithLessons} />}
                        <select class="form-select" aria-label="Default select example" onChange={selectedLessonChangeHnadler} value={selectedLessonId}>
                            {lessons.map((lesson, i) => {
                                return <option value={lesson.id}>{lesson.title}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </Card>
            <hr />
            {lessons.length > 0 &&
                <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectedHandler(index)}>
                    <TabList>
                        <Tab>محتوای جلسه</Tab>
                        <Tab>مطالب</Tab>
                        <Tab>آزمون / تکالیف</Tab>
                        <Tab>تنظیمات</Tab>
                    </TabList>

                    <TabPanel>

                        {/* <Card> */}
                        <div>
                            {/* <input onClick={() => goToLessonEditorPage()} type="button" className="btn btn-success btn-sm mx-2" value="ویرایش"></input>
                            <input onClick={() => goToPreviewPage()} type="button" className="btn btn-primary btn-sm mx-2" value="نمایش"></input>
                            <input onClick={() => goToSlidePage()} type="button" className="btn btn-info btn-sm mx-2" value="نمایش اسلاید"></input> */}
                            {!isLoading &&
                                <LessonContentComponent key={lesson.id} lesson={lesson} />
                            }
                        </div>
                        {isLoading &&
                            <div class="spinner-grow text-primary position-absolute top-50 start-50" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel>
                        {lesson.id != -1 && bookId != -1 && <SecondaryContentComponent lessonId={lesson.id} bookId={bookId} />}
                    </TabPanel>
                    <TabPanel>
                        <AzmoonPage key={counterForKey} lessonId={lesson.id} />
                    </TabPanel>
                    <TabPanel>
                        <LessonSettingComponent lessonId={lesson.id} lessonName={lesson.title} lessonTags={lesson.tags} lessonOrder={lesson.order} getLessons={getBookWithLessons} />
                    </TabPanel>
                </Tabs>
            }


        </div>

    );
};

export default LessonPage;



