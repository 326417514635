import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import lessonService from '../../services/lesson.service';
import api from '../../services/API';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import htmlContentService from '../../services/htmlContent.service';

const LessonPreview = (props) => {
    const { htmlContentId } = useParams();
    const [htmlContent, setHtmlContent] = useState([]);

    useEffect(() => {
        getHtmlContentById(htmlContentId);
    }, []);

    // function getLessonByIdWithHtmls(lessonId) {
    //     api.get('/Teacher/Lesson/GetLessonByIdWithHtmls/' + lessonId).then(
    //         (response) => {
    //             setLesson(response.data);
    //             setMainHtmlContent(response.data.htmlContents.find(item => { return item.isMainContent == true }));
    //         },
    //         (error) => {
    //         }
    //     );
    // }

    function getHtmlContentById(htmlContentId) {
        htmlContentService.getHtmlContentById(htmlContentId).then(
            (response) => {
                setHtmlContent(response.data.htmlContent);
            },
            (error) => {
            }
        );
    }

    return (
        <div className="w-100" style={{ padding: '10px' }}>
            <style>{htmlContent.css}</style>
            <div dangerouslySetInnerHTML={{ __html: htmlContent.htmlText }} />
        </div>
    );
};

export default LessonPreview;



