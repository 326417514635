import Content from "../components/Layout/card/Content";
import Card from "../components/Layout/card/Card";
import Title from "../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import academyStudentsService from "../services/academyStudents.service";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ManagerHomePage from "./ManagerHomePage";

const HomePage = (props) => {
    const [isLoading, setIsLoading] = useState(-1);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {

    }, [])

    function hasRole(name) {
        for (let i in currentUser.roles) { //loops over array indexes
            if (currentUser.roles[i] === name) { //case sensitive
                return true;
            }
        }
        return false;
    }

    return (
        <div className="w-100">
            {hasRole("Manager") && <ManagerHomePage />}
        </div>
    );
};

export default HomePage;



