import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import courseStudentsService from "../../services/courseStudents.service";
import courseService from "../../services/course.service";
import studentRegisterService from "../../services/student.register.service";


const RegisterStudentInCourse = () => {
    const navigate = useNavigate();
    const { courseRegisterationCode } = useParams();
    const [studentName, setStudentName] = useState("");
    const [studentFamily, setStudentFamily] = useState("");
    const [studentMobile, setStudentMobile] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [isInOtpVerifyCodePage, setIsInOtpVerifyCodePage] = useState(false);
    const [isShowSuccessfullPage, setIsShowSuccessfullPage] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // getCourseByRegisterationCode(courseRegisterationCode);
    }, []);

    // function getCourseByRegisterationCode(registerationCode) {
    //     courseService.getCourseByRegisterationCode(registerationCode).then(
    //         (response) => {
    // setLogoFileName(response.data["logo"]);
    // setCourseInfo(response.data);
    //         },
    //         (error) => {

    //         }
    //     );
    // }

    function verifyAndInsertStudent() {
        if (otpCode == '') {
            toast.error("کد را وارد کنید");
            return;
        }
        studentRegisterService.verifyOtpCodeAndRegister(otpCode, studentMobile, studentName, studentFamily, courseRegisterationCode).then(
            (response) => {
                setIsShowSuccessfullPage(true);
            },
            (error) => {
                // toast.error(error.response.data.message);
            }
        );
    }

    function sendOtpsms() {
        if (studentName == "") {
            toast.info("وارد کردن نام الزامی است");
            return;
        }
        if (studentFamily == "") {
            toast.info("وارد کردن فامیل الزامی است");
            return;
        }
        if (studentMobile == "") {
            toast.info("وارد کردن موبایل الزامی است");
            return;
        }

        studentRegisterService.sendSms(studentMobile).then(
            (response) => {
                toast.info(response.data.message);
                setIsInOtpVerifyCodePage(true);
            },
            (error) => {
                // toast.info(error.message);
            }
        );
    }

    function toggleOtpPage() {
        setIsInOtpVerifyCodePage(!isInOtpVerifyCodePage);
    }

    function studentNameChange(e) {
        setStudentName(e.target.value);
    }

    function studentFamilyChange(e) {
        setStudentFamily(e.target.value);
    }

    function studentMobileChange(e) {
        setStudentMobile(e.target.value);
    }

    function otpCodeChange(e) {
        setOtpCode(e.target.value);
    }

    return (
        <div class="row mt-1">
            {isShowSuccessfullPage &&
                <div className="position-absolute top-50 start-50 translate-middle text-center">
                    ثبت نام شما با موفقیت انجام شد
                </div>
            }
            <div class="col-md-4"></div>

            {!isShowSuccessfullPage && isInOtpVerifyCodePage &&
                <div class="col-md-4 border mt-2 p-0 text-center">
                    <p className="border p-3 w-100">تایید شماره موبایل</p>
                    <div className="p-3">
                        <label class="control-label mb-3" for="first-name">کد ارسال شده به شماره موبایل <span className="bg-light">{studentMobile}</span> را وارد کنید:</label>
                        <input value={otpCode} onChange={otpCodeChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                        <div className="text-center">
                            <button type="submit" class="btn btn-primary mt-3" onClick={() => verifyAndInsertStudent()} >ثبت</button>
                        </div>
                        <hr />
                        <div className="text-center">
                            <button type="submit" class="btn btn-danger mt-3 mx-2" onClick={() => toggleOtpPage()}>بازگشت</button>
                            <button type="submit" class="btn btn-primary mt-3" onClick={() => sendOtpsms()}>ارسال  دوباره کد(10)</button>
                        </div>
                    </div>
                </div>
            }
            {!isShowSuccessfullPage && !isInOtpVerifyCodePage &&
                <div class="col-md-4 border p-3">
                    <p className="text-center">ثبت نام هنرجو</p>
                    <hr />
                    <div class="form-group mt-2">
                        <label class="control-label" for="first-name">نام *</label>
                        <input value={studentName} onChange={studentNameChange} type="text" id="first-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                    <div class="form-group mt-3">
                        <label class="control-label" for="last-name">نام خانوادگی *</label>
                        <input value={studentFamily} onChange={studentFamilyChange} type="text" id="last-name" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>
                    <div class="form-group mt-3">
                        <label class="control-label" for="mobile">شماره موبایل *</label>
                        <input value={studentMobile} onChange={studentMobileChange} type="text" pattern="\d*" id="mobile" required="required" class="form-control col-md-7 col-xs-12" />
                    </div>

                    {/* <input class='mt-4' type="checkbox" value="" disabled checked />ارسال نام کاربری و رمز عبور به موبایل دانشجو */}
                    <br />
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-around">
                            <button type="submit" class="btn btn-success" onClick={() => sendOtpsms()}>ثبت</button>
                            <button type="submit" class="btn btn-primary" onClick={() => navigate('/sc/' + courseRegisterationCode)}>انصراف</button>

                        </div>
                    </div>
                </div>
            }
            <div class="col-md-4"></div>

            <ToastContainer position="top-left" rtl />

        </div>
    );
};

export default RegisterStudentInCourse;



